// cancellation.service.ts
import { Injectable, OnDestroy, inject } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { Subscription } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class HttpCancellationService implements OnDestroy {
    private controllers = new Set<AbortController>();
    private routerSub: Subscription;
    private router = inject(Router);

    constructor() {
        this.routerSub = this.router.events.subscribe((event) => {
            if (event instanceof NavigationStart) {
                this.cancelAll();
            }
        });
    }

    register(abortController: AbortController) {
        this.controllers.add(abortController);
    }

    unregister(abortController: AbortController) {
        this.controllers.delete(abortController);
    }

    cancelAll() {
        this.controllers.forEach((ctrl) => ctrl.abort());
        this.controllers.clear();
    }

    ngOnDestroy() {
        this.routerSub.unsubscribe();
        this.cancelAll();
    }
}
