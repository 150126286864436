import { TLabelValue } from './types';

export enum EActionTypes {
    EDIT,
    ROUTER,
    MENU,
    VIEW,
    DELETE,
    SAVE,
    REFRESH,
}

export enum EF101Mimshak {
    MALAM,
    MICHPAL,
    HILAN,
    OKETZ,
    HARGAL,
    SHIKLOLIT,
    MEIRAV,
    SYNEL,
}

export enum EIDType {
    ISRAELI_ID = 'israeliId',
    PASSPORT = 'passport',
}

export enum EB360AlertTypes {
    INFO = 'info',
    ERROR = 'error',
    SUCCESS = 'success',
    BASE = 'base',
    ATTENTION = 'attention',
}

export enum EMoveBtnType {
    PREV = 'prev',
    NEXT = 'next',
}

export enum EExcelDownloadType {
    ALL = 'all',
    FILTERED = 'filtered',
}

export enum EB360Lang {
    HEBREW = 'he',
    ENGLISH = 'en',
    AMHARIC = 'am',
    ARABIC = 'ar',
    RUSSIAN = 'ru',
    FRENCH = 'fr',
    SPANISH = 'es',
}

export enum EB360FormFieldsTypes {
    TEXT = 'text',
    SELECT = 'select',
    DATE = 'date',
    NUMBER = 'number',
    CHECKBOX = 'checkbox',
    RADIO = 'radio',
    FILE = 'file',
    TEXTAREA = 'textarea',
    PASSWORD = 'password',
    EMAIL = 'email',
    TEL = 'tel',
    SELECT_AUTOCOMPLETE = 'selectAutoComplete',
    B360_MULTIPLE_SELECT = 'b360multipleSelect',
    COUNTRY_SELECT = 'countrySelect',
    JOINED_ID_FIELD = 'joinedIdField',
    JOINED_CONNECTION_FIELD = 'joinedConnectionField',
    AUDIENCE_SELECT = 'audienceSelect',
    CHECKBOX_WITH_SELECT = 'checkboxWithSelect',
}

export enum EB360Routes {
    HOME = '/employee/dashboard',
    TIMEWATCH = '/employee/timewatch',
}

export enum EContentType {
    BINARY = 'binary/octet-stream',
    JSON = 'application/json',
    PDF = 'application/pdf',
    JPEG = 'image/jpeg',
    PNG = 'image/png',
    MP4 = 'video/mp4',
    MP3 = 'audio/mpeg',
    FORM = 'application/x-www-form-urlencoded',
    TEXT = 'text/plain',
    HTML = 'text/html',
    XML = 'application/xml',

    // multipart/form-data
    FORM_DATA = 'multipart/form-data',

    // application/x-www-form-urlencoded;charset=utf-8
    FORM_URLENCODED = 'application/x-www-form-urlencoded;charset=utf-8',

    // application/json;charset=utf-8
    JSON_UTF8 = 'application/json;charset=utf-8',
}

export const ContentTypeMap: { [key: string]: EContentType } = {
    pdf: EContentType.PDF,
    jpg: EContentType.JPEG,
    jpeg: EContentType.JPEG,
    png: EContentType.PNG,
    mp4: EContentType.MP4,
    mp3: EContentType.MP3,
    bin: EContentType.BINARY,
};

export enum EBtnTypes {
    BTN,
    FLAT,
    RAISED,
    ICON,
    FAB,
    MINI_FAB,
    STROKED,
}

export enum EB360Bucket {
    DOCS,
    MAIN,
    IMAGES,
}

export enum EEmployeeState {
    ACTIVE = 'ACTIVE',
    INACTIVE = 'INACTIVE',
    ONLEAVE = 'ONLEAVE',
    ONBOARDING = 'ONBOARDING',
    OFFBOARDING = 'OFFBOARDING',
    HEARING = 'HEARING',
    SUSPENDED = 'SUSPENDED',
}

export const EMPLOYEE_STATE_LIST = [
    EEmployeeState.ACTIVE,
    EEmployeeState.INACTIVE,
    EEmployeeState.ONLEAVE,
    EEmployeeState.ONBOARDING,
    EEmployeeState.OFFBOARDING,
    EEmployeeState.HEARING,
    EEmployeeState.SUSPENDED,
];

export interface ITableFilterSelectionModel extends TLabelValue {
    selected: boolean;
}

export interface ITableFilterSelectionModel2 {
    [key: string]: string | boolean;
    selected: boolean;
}

export interface ITableFilterSelectionEvent {
    filterData: ITableFilterSelectionModel[];
    selected: any[];
    allSelected: boolean;
}

export enum TableEvents {
    DOWNLOAD_FILE,
    FORWARD_DOC_TO,
    REFRESH_TABLE,
    UPLOAD_FILE,
    VIEW_PDF,
    APPROVE,
    REJECT,
    NUDGE,
    VIEW_IMG,
    NAVIGATE_TO,
    DEACTIVATE,
    ACTIVATE,
    REMOVE,
    DELETE,
    SEND_CUSTOM_SMS,

    // use for editing a row in a table.
    EDIT,
    // for handling edit not on the table itself.
    EDIT_EVENT,
    NEW_DOC,
    COPY,
    GENERATE_EXCEL,
}

export enum EPesnionTypes {
    NEW_PENSION = 'pension',
    STUDYFUND = 'histalmot',
}

export enum EProcessType {
    OTHER = 'other',
    OFFBOARDING = 'offboarding',
    ONBOARDING = 'onboarding',
}

export enum TableActions {
    MAKE_VISIBLE_TO_EMP,
    NEW_TEMPLATE,
    CLOSE_OPEN_PROCESSES,
    DOWNLOAD_DOCUMENTS_DATA,
    HIDE_FROM_EMP,
    ADD_NEW = 'addNew',
    ACTIVATE = 'activate',
    DONWLOAD_ALL_FILES = 'downloadAllFiles',
    REJECT_PROCESSES = 'rejectProcesses',
    ADD_DEPARTMENT = 'addDepartment',
    ADD_FACTORY = 'addFactory',
    ADD_PENSION = 'addPension',
    ADD_REPLACE_ONBOARDING = 'addReplaceOnboarding',
    ADD_SHIFT = 'addShift',
    ADD_TASK = 'addTask',
    ADD_TIMEWATCH_PROJECT = 'addTimewatchProject',
    APPOVE_SELECTION = 'approveSelection',
    APPROVE_PROCESSES = 'approveProcesses',
    ATTENDANCE_TABLE = 'attendance',
    CANCEL_APPOVE_SELECTION = 'cancelApproveSelection',
    CHANGES = 'changes',
    CHANGE_STATUS_UPDATED_IN_SALARY = 'updatedInSalaryStatus',
    COPY = 'copy',
    DEACTIVATE_EMPLOYEE = 'deactivateEmployee',
    DEACTIVATE_MULTIPLE_EMPLOYEES = 'deactivateMultipleEmployees',
    DELETE = 'delete',
    DELETE_MANY = 'delete_many',
    DOWNLOAD_MULITPLE_FILES = 'downloadMultipleFiles',
    EDIT = 'edit',
    FORWARD_DOC_TO = 'ForwardDocs',
    LOCK_MONTH = 'lockMonth',
    MARK_ALL_AS_DONE = 'markAllAsDone',
    MIMSHAK = 'mimshak',
    NEW_AGENT = 'newAgent',
    NEW_CUSTOM_FIELD = 'newCustomField',
    NEW_DOC = 'newDoc',
    NEW_ELIGIBILITY = 'newEligibility',
    NEW_KIT = 'newKit',
    NEW_PERMISSION = 'newPermission',
    NEW_ROLE = 'newRole',
    NEW_SETTLEMENT = 'newSettlement',
    NONE = 'none',
    OPEN_DIALOG = 'openDialog',
    PAYSLIPS_TABLE = 'payslips',
    REJECT_SELECTION = 'rejectSelection',
    REMIND_EMPLOYEES = 'remindEmployees',
    SEND_CUSTOM_NOTIFS = 'sendCustomNotifs',
    SEND_DOC = 'send_doc',
    SEND_KIT = 'sendKit',
    SEND_NOTIFS = 'sendNotifs',
    TABLE_DATA_DOWNLOAD = 'downloadTable',
    VIEW_DOC = 'viewDoc',
    ADD_BUSINESS = 'addBusiness',
    UPLOAD_FILE = 'uploadFile',
    REFLECT_FEEDBACK = 'reflectFeedback',
    REFLECT_FEEDBACK_CANCEL = 'reflectFeedbackCancel',
    SEND_BY_API = 'sendByApi',
}

export enum EModule {
    FORM_101 = 'form101',
    AUTH = 'auth',
    ANY = 'any',
    PENSION = 'pension',
    DIGITAL_DOC = 'dg',
    ATTENDANCE = 'attendance',
    EMPLOYEE_PROCESS = 'process',
    PAYSLIP = 'payslip',
    FORM106 = 'form106',
    EMPLOYEES = 'employeesTable',
    SETTINGS = 'settings',
    PERMISSIONS = 'permissions',
    SOCIAL = 'social',
}

export enum EEmployeeModule {
    DASHBOARD = 'dashboard',
    TIME_WATCH = 'timewatch',
    ATTENDANCE = 'attendance',
    PAYSLIPS = 'paySlips',
    BENEFITS = 'benefits',
    FORM_101 = 'form101',
    DOCUMENTS = 'documents',
    PENSION = 'pension',
    PENSION_CLEARING = 'pensionClearing',
    SOCIAL = 'social',
    PHONE_BOOK = 'phoneBook',
    BACK_TO_EMPLOYER = 'backToEmployer',
    LOG_OUT = 'logOut',
    THEME = 'theme',
}

export enum ENAVIGATE_TO {
    FORM101 = 'form101',
    DOCUMENT = 'document',
    TIMEWATCH = 'timewatch',
    ATTENDANCE = 'attendance',
    PENSION = 'pension',
    HISTALMOT = 'histalmot',
    PAYSLIP = 'payslip',
    HOME = 'dashboard',
}

export enum EEmployeeProcess {
    DOCUMENT = 'document',
    FORM101 = 'form101',
    PENSION = 'pension',
    PAYSLIP = 'payslip',
    KITS = 'kits',
    BANK_DETAILS = 'bankDetails',
}

export enum ETaskTypes {
    FORM_101 = 'form101',
    RESUBMIT_101 = 'resubmit101',
    PENSION = 'pension',
    HISTALMOT = 'histalmot',
    DIGITAL_DOC = 'dg',
    ATTENDANCE = 'attendance',
    PAYSLIP = 'payslip',
    FORM106 = 'form106',
    KIT = 'kit',
    CHAT = 'chat',
    TIMEWATCH = 'timewatch',
    BENEFIT = 'benefit',
    FIX_BANK_DETAILS = 'fixBankDetails',
    CUSTOM = 'custom',
}

export enum NotificationMehods {
    BY_EMAIL = 'email',
    BY_SMS = 'sms',
    BY_CUSTOM_SMS = 'customsms',
    BOTH = 'both',
}

export enum EInputTypes {
    BISKITS_AUTOCOMPLETE = 'biskits-autoComplete',
    RADIO = 'radioGroup',
    FILE = 'file',
    DOCS = 'docs_special',
    ID = '_id',
    PICKLIST = 'pickList',
}
export enum ETimeWathcState {
    IN_SHIFT = 'in_shift',
    ON_A_BREAK = 'on_a_break',
    IDLE = 'idle',
    DONE_FOR_THE_DAY = 'done',
    NOT_COMING = 'not_coming',
}

export enum ETimeStampType {
    START_SHIFT = 'start_shift',
    END_SHIFT = 'end_shift',
    START_RECESS = 'start_recess',
    END_RECESS = 'end_recess',
}

export enum ETimeWathcEditorType {
    MANAGER = 'manager',
    EMPLOYEE = 'employee',
}

export enum EResource {
    EMPLOYEES = 'Employee',
    LOCAL_FIELDS = 'localFields',
    BY_POSITION_SIGNATURE = 'positionSignature',
    EMPLOYEE_SIGNATURE = 'employeeSignature',
    STAFF_SIGNATURE = 'staffSignature',
}

export enum ECollections {
    COMPANIES = 'company',
    EMPLOYEES = 'employees',
}

export enum DisplayState {
    EDIT = 'edit',
    VIEW = 'view',
    NEW = 'new',
}

export enum BusinessViewType {
    GENERAL = 'GENERAL',
    DEPARTMENTS = 'DEPARTMENTS',
    EMPLOYEES = 'EMPLOYEES',
    PENSION = 'PENSION',
    CHATROOMS = 'CHATROOMS',
}

export enum GeneralActionTypes {
    DOWNLOAD_TABLE = 'downloadTable',
}

export enum EmployeeHeaderIconTypes {
    DOCUMENTS,
    FORM101,
    PENSION,
    MENU,
    BRIEFCASE,
    ATTENDANCE,
    SALARY,
    BENEFITS,
}

export enum ETableTypes {
    FORM_101_TABLE = 'form101',
    PENSION_TABLE = 'pension',
    DIGITAL_DOC_TABLE = 'dg',
    PROCESS_TABLE = 'process',
    ATTENDANCE_TABLE = 'attendance',
    PAYSLIP_TALBE = 'payslip',
    FORM106_TABLE = 'form106',
    EMPLOYEES_TABLE = 'employeesTable',
    UPLOADED_EMPLOYEES_TABLE = 'uploadedEmployeesTable',
    DEPARTMENTS_TABLE = 'departmentsTable',
    SETTINGS_TABLE = 'settings',
    ATTENDANCE_MAP = 'attendanceMap',
    PERMISSIONS_TABLE = 'permissions',
    CUSTOM_USER = 'customuser',
    KITS_TABLE = 'kits',
    BUSINESS_PERMISSION_TABLE = 'BUSINESS_MANAGER',
    FACTORY_PERMISSION_TABLE = 'FACTORY_MANAGER',
    DEPARTMENT_PERMISSION_TABLE = 'DEPARTMENT_MANAGER',
    EMPLOYMENT_KITS = 'process',
    EMPLOYEES_MISSIONS = 'employeesmissions',
}

export enum ECollectionTypes {
    ATTENDANCE_MAP_COL,
    CUSTOM_FIELDS_MAP_COL,
}

export enum EAppendTypes {
    ID = 'ID',
}

export enum BusinessEmployeeViewType {
    FORMS,
    PENSION,
    SALARY,
    TIME,
}

export enum HPViewType {
    MAIN = 1,
}

export enum FilterParamsQty {
    ONEPARAM = 1,
    TWOPARAM = 2,
}

export enum GeneralOrOrganizationView {
    GENERAL = 'GENERAL',
    ORGANIZATION = 'ORGANIZATION',
}

export enum InputsEnums {
    TEXTORDATE = 1,
    AUTOCOMPLETE = 2,
    DROPDOWNSINPUT = 3,
    DROPDOWNOPTIONS = 4,
    RADIOGROUP = 5,
    RADIOOPTIONS = 6,
    TEXTONLY = 7,
    SPECIALPHONE = 8,
    SPECIALDATE = 9,
    SPECIALTEXT = 10,
}

export enum MessageType {
    FILL_FORM101 = 'FILL_FORM101',
    NEW_PAYSLIP = 'NEW_PAYSLIP',
    SYSTEM_INVITATION = 'SYSTEM_INVITATION',
    BIRTHDATE = 'BIRTHDATE',
    NEW_CHAT_MSG = 'NEW_CHAT_MSG',
    NEW_DOCUMENT = 'NEW_DOCUMENT',
    ONBOARDING = 'ONBOARDING',
    PENSION = 'PENSION',
    HISTALMOT = 'HISTALMOT',
    PENSION_AND_HISTALMOT = 'PENSION_AND_HISTALMOT',
    REMAINDER = 'REMAINDER',
    OTHER = 'OTHER',
}

export enum EAttendanceOptions {
    VACATION = 'vacation',
    MILITARY = 'military',
    SICK = 'sick',
    PREGNANCY = 'pregnancy',
    REGULAR = 'regular',
    DEATH = 'death',
    HOLIDAY = 'holiday',
    UNKNOWN = 'unknown',
    OTHER = 'other',
    GROUP = 'group',
    OUT_MISSING = 'out_missing',
    IN_MISSING = 'in_missing',
}

export enum ETimeWathcEmployeeState {
    IN_SHIFT = 'in_shift',
    ON_A_BREAK = 'on_a_break',
    IDLE = 'idle',
    DONE_FOR_THE_DAY = 'done',
    NOT_COMING = 'not_coming',
}

export enum SystemModulesType {
    TOFES101 = 'טופס 101',
    PENSION = 'פנסיוני',
    BUSINESSFORM = 'מסמכי העסקה',
    STUDYFUNDS = 'קרן השתלמות',
}

export enum StatusHeb {
    EMPLOYEE_PENDING,
    EMPLOYEER_PENDING,
    RESUBMIT,
    IDLE,
    APPROVED,
}

export enum State {
    IDLE = 'IDLE',
    HIDDEN_FROM_EMPLOYEE = 'HIDDEN_FROM_EMPLOYEE',
    FILE_IN_PROCESS = 'FILE_IN_PROCESS',
    EMPLOYEE_NOT_FOUND = 'EMPLOYEE_NOT_FOUND',
    SENT_TO_EMPLOYEE = 'SENT_TO_EMPLOYEE',
    EMPLOYEE_PENDING = 'EMPLOYEE_PENDING',
    RESUBMIT = 'RESUBMIT',
    EMPLOYER_PENDING = 'EMPLOYER_PENDING',
    EMPLOYER_REJECTED = 'EMPLOYER_REJECTED',
    EMPLOYEE_DECLIEND = 'EMPLOYEE_DECLIEND',
    APPROVED = 'APPROVED',
    VIEWED_BY_EMPLOYEE = 'VIEWED_BY_EMPLOYEE',
    DELETED_BY_EMPLOYER = 'DELETED_BY_EMPLOYER',
    IN_PROCESS = 'IN_PROCESS',
    EMPLOYEE_DONE = 'EMPLOYEE_DONE',
    STUCK = 'STUCK',
    CREATING = 'CREATING',
    UPDATED_IN_SALARY = 'UPDATED_IN_SALARY',
    DONE = 'DONE',
    SKIPPED_BY_EMPLOYEE = 'SKIPPED_BY_EMPLOYEE',
    FINISHED_WITH_ERRORS = 'FINISHED_WITH_ERRORS',
    STOPPED = 'STOPPED',
    AWAITING_MANAGER_APPROVAL = 'AWAITING_MANAGER_APPROVAL',
    AWAITING_MANAGER_FILL = 'AWAITING_MANAGER_FILL',
    AWAITING_YOU = 'AWAITING_YOU',
}

export const VIEWABLE_STATES = [
    State.RESUBMIT,
    State.EMPLOYER_PENDING,
    State.EMPLOYEE_DONE,
    State.AWAITING_MANAGER_APPROVAL,
    State.AWAITING_MANAGER_FILL,
    State.APPROVED,
    State.UPDATED_IN_SALARY,
    State.DONE,
    State.AWAITING_YOU,
];

export const inCompleteStates = [
    State.IDLE,
    State.FILE_IN_PROCESS,
    State.EMPLOYEE_NOT_FOUND,
    State.SENT_TO_EMPLOYEE,
    State.EMPLOYEE_PENDING,
    State.RESUBMIT,
    State.EMPLOYER_PENDING,
    State.VIEWED_BY_EMPLOYEE,
    State.IN_PROCESS,
    State.STUCK,
    State.CREATING,
    State.EMPLOYEE_DONE,
    State.STOPPED,
    State.AWAITING_MANAGER_APPROVAL,
    State.AWAITING_MANAGER_FILL,
];

export const completedStates = [
    State.DELETED_BY_EMPLOYER,
    State.EMPLOYER_REJECTED,
    State.UPDATED_IN_SALARY,
    State.DONE,
    State.SKIPPED_BY_EMPLOYEE,
    State.APPROVED,
    State.FINISHED_WITH_ERRORS,
];

export const BcpStateDictionary = {
    PENDING: 'ממתין לאישור',
    APPROVED: 'אושר',
};

export const FormsTabelDictionary = {
    business: 'כולם',
    factory: 'מפעל/ים',
    departments: 'מחלקה/ות',
    employee: 'עובד/ים נבחרים',
};

export const newStatusToHeb = {
    EMPLOYER_REJECTED: 'נדחה',
    APPROVED: 'אושר',
    EMPLOYER_PENDING: 'ממתין',
};
export const StateToHeb = {
    EMPLOYEE_PENDING: 'ממתין למילוי ',
    IN_PROCESS: 'בתהליך מילוי',
    EMPLOYEE_FINISHED: 'עובד סיים תהליך',
    employer_pending: 'ממתין לאישור ',
    EMPLOYER_PENDING: 'ממתין לאישור ',
    UPDATED_IN_SALARY: 'עודכן בשכר',
    STUCK: 'תקוע ',
    EMPLOYER_REJECTED: 'נדחה ע"י מעסיק',
    EMPLOYEE_DONE: 'עובד סיים למלא',
    EMPLOYEE_REJECTED: 'נדחה ע"י העובד',
    employer_rejected: 'נדחה ע"י מעסיק',
    RESUBMIT: 'נשלח לתיקון',
    IDLE: 'טרם נשלח',
    SENT_TO_EMPLOYEE: 'נשלח לעובד',
    EMPLOYEE_NOT_FOUND: 'עובד לא קיים ',
    VIEWED_BY_EMPLOYEE: 'נצפה ע"י עובד',
    APPROVED: 'אושר',
    DONE: 'משימה הושלמה',
    approved: 'אושר',
    done: 'ממתין לאישור',
    in_shift: 'במשמרת',
    on_a_break: 'בהפסקה',
    idle: 'אין דיווח',
    updated_in_salary: 'עודכן בשכר',
    none: 'אין דיווח',
    not_coming: 'העדרות',
    DELETED_BY_EMPLOYER: 'נמחק ע"י מעסיק',
    SKIPPED_BY_EMPLOYEE: 'דולג ע"י העובד',
    FILE_IN_PROCESS: 'בתהליך',
    FINISHED_WITH_ERRORS: 'הסתיים עם שגיאות',
    AWAITING_MANAGER_APPROVAL: 'ממתין לאישור מנהל',
    AWAITING_MANAGER_FILL: 'ממתין למילוי מנהל',
};

export const StatusHebDictionary = {
    EMPLOYEE_PENDING: 'ממתין למילוי ',
    IN_PROCESS: 'בתהליך מילוי',
    EMPLOYEE_FINISHED: 'עובד סיים תהליך',
    employer_pending: 'ממתין לאישור ',
    EMPLOYER_PENDING: 'ממתין לאישור ',
    UPDATED_IN_SALARY: 'עודכן בשכר',
    STUCK: 'תקוע ',
    EMPLOYER_REJECTED: 'נדחה ע"י מעסיק',
    EMPLOYEE_DONE: 'עובד סיים למלא',
    EMPLOYEE_REJECTED: 'נדחה ע"י העובד',
    employer_rejected: 'נדחה ע"י מעסיק',
    RESUBMIT: 'נשלח לתיקון',
    IDLE: 'טרם נשלח',
    SENT_TO_EMPLOYEE: 'נשלח לעובד',
    EMPLOYEE_NOT_FOUND: 'עובד לא קיים ',
    VIEWED_BY_EMPLOYEE: 'נצפה ע"י עובד',
    APPROVED: 'אושר',
    DONE: 'משימה הושלמה',
    approved: 'אושר',
    done: 'ממתין לאישור',
    in_shift: 'במשמרת',
    on_a_break: 'בהפסקה',
    idle: 'אין דיווח',
    updated_in_salary: 'עודכן בשכר',
    none: 'אין דיווח',
    not_coming: 'העדרות',
    DELETED_BY_EMPLOYER: 'נמחק ע"י מעסיק',
    SKIPPED_BY_EMPLOYEE: 'דולג ע"י העובד',
    FILE_IN_PROCESS: 'בתהליך',
    FINISHED_WITH_ERRORS: 'הסתיים עם שגיאות',
    AWAITING_MANAGER_APPROVAL: 'ממתין לאישור מנהל',
    AWAITING_MANAGER_FILL: 'ממתין למילוי מנהל',
};

export const AttendanceOptionsHebDictionary = {
    military: 'מילואים',
    vacation: 'חופשה',
    sick: 'מחלה',
    group: 'יותר מיום',
    pregnancy: 'ח. לידה',
    death: 'אבל',
    regular: 'רגיל',
    out_missing: 'חסרה יציאה',
    in_missing: 'חסרה כניסה',
    holiday: 'חג',
    unknown: 'אחר',
    other: 'אחר',
    strike: 'שביתה',
};
export const ProductTypeHebDictionary = {
    pension: 'קרן פנסיה',
    activePension: 'קרן פנסיה',
    newPension: 'קרן פנסיה',
    oldPension: 'פנסיה ותיקה',
    gemel: 'קופת גמל',
    newGemel: 'קופת גמל',
    menahalim: 'ביטוח מנהלים',
    histalmot: 'קרן השתלמות',
    agent: 'סוכן מטפל',
    fullPension: 'פנסיה מקיפה',
    generalPension: 'פנסיה כללית',
};
export const ProductStatusDictionary = {
    newPension: 'תוכנית חדשה',
    activePension: 'תוכנית פעילה',
    oldPension: 'תוכנית פעילה',
    gemel: 'תוכנית פעילה',
    newGemel: 'תוכנית חדשה',
    menahalim: 'תוכנית פעילה',
    histalmot: 'תוכנית חדשה',
};

export const TypesToHeb = {
    select: 'בחירה מרשימה',
    text: 'טקסט חופשי',
    date: 'שדה תאריך',
    number: 'מספרים בלבד',
    payslip: 'תלוש שכר',
    form101: 'טופס 101',
    document: 'מסמך',
    pension: 'פנסיה',
    form106: 'טופס 106',
};

export const RoleHebDictionary = {
    ADMIN: 'אדמין',
    OWNER: 'בעלים',
    SYSTEM_MANAGER: 'מנהל מערכת',
    BUSINESS_MANAGER: 'מנהל עסק',
    FACTORY_MANAGER: 'מנהל מפעל',
    DEPARTMENT_MANAGER: 'מנהל מחלקה',
    SIMPLE: 'עובד',
};

export const ForDictionary = {
    BUSINESS: 'כולם',
    FACTORY: 'מפעל/ים',
    DEPARTMENTS: 'מחלקה/ות',
};

export const MessegeTypeDicToHeb = {
    NEW_DOCUMENT: 'מסמך חדש למילוי',
    FILL_FORM101: 'מילוי טופס 101',
    SYSTEM_INVITATION: 'הוזמנת להצטרף אלינו',
    BIRTHDATE: 'מזל טוב !!!',
    NEW_CHAT_MSG: 'יש לך הודעה חדשה',
    OTHER: 'כל מיני',
};
export const MTypeDicToHeb = {
    NEW_DOCUMENT: 'מסמכים חדשים',
    FILL_FORM101: 'טפסי 101',
    SYSTEM_INVITATION: 'הוזמנת להצטרף אלינו',
    BIRTHDATE: 'מזל טוב !!!',
    NEW_CHAT_MSG: 'הודעות אחרונות',
    OTHER: 'כל מיני',
};

export const NotificationRouter = {
    FILL_FORM101: '/tofes101',
    NEW_DOCUMENT: '/forms',
    SYSTEM_INVITATION: './',
    BIRTHDATE: './',
    NEW_CHAT_MSG: '/chat',
    OTHER: './',
};

export const NotifToIcon = {
    FILL_FORM101: 'f101',
    NEW_PAYSLIP: 'payslipOn',
    NEW_DOCUMENT: 'docsOn',
    SYSTEM_INVITATION: 'homeOn',
    BIRTHDATE: 'benefits',
    NEW_CHAT_MSG: 'chatOn',
    PENSION_AND_HISTALMOT: 'pension',
    PENSION: 'pension',
    REMAINDER: 'notifsOn',
    HISTALMOT: 'pension',
    OTHER: 'כל מיני',
};

export const PermissionHebDictionary = {
    EDIT: 'עריכה',
    VIEW: 'צפיה בלבד',
    NONE: 'ללא',
};

export const EmployeePropsDic = {
    prefixName: 'תואר',
    idNumber: 'ת.ז',
    firstName: 'שם פרטי',
    lastName: 'שם משפחה',
    position: 'תפקיד',
    employeeNumber: 'מספר עובד',
    email: 'דוא"ל',
    phoneNumber: 'נייד',
    birthDate: 'גיל',
    startDate: 'ותק',
    city: 'עיר',
    street: 'רחוב',
    houseNumber: 'מספר בית',
    apartmentNumber: 'מספר דירה',
    accountNumber: 'חשבון',
    bankName: 'שם בנק',
    bank: 'בנק',
    bankNumber: 'מספר בנק',
    branchName: 'שם סניף',
    branch: 'סניף',
    branchNumber: 'מספר סניף',
};
export const CompanyPropsDic = {
    deductionNumber: 'תיק ניכויים',
    companyName: 'שם',
    companyNumber: 'ח.פ',
    phoneNumber: 'נייד',
    birthDate: 'גיל',
    startDate: 'ותק',
    city: 'עיר',
    street: 'רחוב',
    houseNumber: 'מספר בית',
    apartmentNumber: 'מספר דירה',
    accountNumber: 'חשבון',
    bankName: 'שם בנק',
    bank: 'בנק',
    bankNumber: 'מספר בנק',
    branchName: 'שם סניף',
    branch: 'סניף',
    branchNumber: 'מספר סניף',
};

export enum FormsState {
    IDLE = 'IDLE',
    APPROVED = 'APPROVED',
    EMPLOYEE_PENDING = 'EMPLOYEE_PENDING',
    RESUBMIT = 'RESUBMIT',
    EMPLOYER_PENDING = 'EMPLOYER_PENDING',
    EMPLOYER_REJECTED = 'EMPLOYER_REJECTED',
    UPDATED_IN_SALARY = 'UPDATED_IN_SALARY',
}

export enum ConnectionType {
    MOBILE = 'MOBILE',
    DESKTOP = 'DESKTOP',
}

export enum Role {
    ADMIN = 'ADMIN',
    OWNER = 'OWNER',
    ORGANIZATION_MANAGER = 'ORGANIZATION_MANAGER',
    BUSINESS_MANAGER = 'BUSINESS_MANAGER',
    FACTORY_MANAGER = 'FACTORY_MANAGER',
    DEPARTMENT_MANAGER = 'DEPARTMENT_MANAGER',
}

export enum ELanguage {
    HEBREW = 'HEBREW',
    ENGLISH = 'ENGLISH',
    AMHARIC = 'AMHARIC',
    RUSSIAN = 'RUSSIAN',
    ARABIC = 'ARABIC',
    FRENCH = 'FRENCH',
    SPANISH = 'SPANISH',
}

export enum SuprviseOn {
    BUSINESS = 'BUSINESS',
    FACTORY = 'FACTORY',
    DEPARTMENT = 'DEPARTMENT',
}
export enum EPermission {
    EDIT = 'EDIT',
    VIEW = 'VIEW',
    NONE = 'NONE',
    ALL = 'BOTH',
}

export enum EPrivilege {
    VIEW_ONLY = 'VIEW_ONLY',
    NONE = 'NONE',
    FULL_ACCESS = 'FULL_ACCESS',
}

export enum EFlow {
    FORM101 = 'form101',
    PENSION = 'pension',
    DOCUMENTS = 'documents',
    STUDYFUNDS = 'studyfunds',
}

export const CFlowToHeb = {
    BUSINESS: 'כל המעסיק',
    CUSTOM: 'מותאם אישית',
    FACTORY: 'למפעל/ים',
    DEPARTMENT: 'למחלקה/ות',
    FORM101: 'טופס 101',
    PENSION: 'פנסיה',
    DOCUMENTS: 'טפסי קליטה',
    STUDYFUNDS: 'קרן השתלמות',
    TOALL: 'לכל העסק',
    TOFACTORY: 'למפעל/ים',
    TODEPARTMENTS: 'למחלקה/ות',
};

export const recipientTypeToHeb = {
    everyone: 'כולם',
    custom: 'מותאם אישית',
    factory: 'למפעל/ים',
    department: 'למחלקה/ות',
    personal: 'רמת עובד',
};

export const processTypeToHeb = {
    onboarding: 'קליטה',
    offboarding: 'עזיבה',
    other: 'אחר',
};

export const EProductTypeToHeb = {
    newPension: 'קרן פנסיה',
    activePension: 'קרן פנסיה',
    oldPension: 'פנסיה ותיקה',
    activeGemel: 'קופת גמל',
    newGemel: 'קופת גמל',
    menahalim: 'ביטוח מנהלים',
    histalmot: 'קרן השתלמות',
};

export const EligibilityToHeb = {
    BUSINESS: 'כל המעסיק',
    CUSTOM: 'מותאם אישית',
    FACTORY: 'למפעל/ים',
    DEPARTMENT: 'למחלקה/ות',
    pension: 'פנסיה',
    histalmot: 'קרן השתלמות',
    '0': 'מיידי',
    '1m': 'חודש',
    '3m': 'שלושה חודשים',
    '6m': 'חצי שנה',
    '1y': 'שנה',
    '2y': 'שנתיים',
    '3y': 'שלוש שנים',
    law: 'לפי חוק',
};

export const AttendanceToHebMap = {
    false: 'נטען',
    true: 'לא נטען',
};

export const AttendanceTypes = [
    { value: EAttendanceOptions.REGULAR, label: 'רגיל' },
    { value: EAttendanceOptions.HOLIDAY, label: 'חג' },
    { value: EAttendanceOptions.MILITARY, label: 'מילואים' },
    { value: EAttendanceOptions.PREGNANCY, label: 'ח. לידה' },
    { value: EAttendanceOptions.SICK, label: 'מחלה' },
    { value: EAttendanceOptions.GROUP, label: 'יותר מיום' },
    { value: EAttendanceOptions.VACATION, label: 'חופשה' },
    { value: EAttendanceOptions.DEATH, label: 'אבל' },
    { value: EAttendanceOptions.OTHER, label: 'אחר' },
];

export enum ECustomFieldType {
    SELECT = 'select',
    TEXT = 'text',
    NUMBER = 'number',
    DATE = 'date',
}

export enum ERecipientType {
    EVERYONE = 'everyone',
    ORGANIZATION = 'organization',
    BUSINESS = 'business',
    FACTORY = 'factory',
    DEPARTMENT = 'department',
    CUSTOM = 'custom',
    PERSONAL = 'personal',
}

export enum EEmployeeType {
    FULL_TIME = 'fullTime',
    PART_TIME = 'partTime',
    CONTRACT = 'contract',
    TEMPORARY = 'temporary',
    OTHER = 'other',
    FOREIGN = 'foreign',
    FOREIGN_TEMPORARY = 'foreignTemporary',
    FOREIGN_FULL_TIME = 'foreignFullTime',
}

export enum EDocumentType {
    FOLDER = 'folder',
    PDF = 'pdf',
    WORD = 'word',
    EXCEL = 'excel',
    IMAGE = 'image',
}
export const EmployeeModelToHeb = {
    idNumber: 'ת.ז',
    name: 'שם העובד',
    position: 'תפקיד',
    employeeNumber: 'מספר עובד',
    email: 'דוא"ל',
    phoneNumber: 'נייד',
    birthDate: 'גיל',
    startDate: 'ותק',
    idType: 'סוג ת.ז',
    country: 'מדינת מוצא',
    countryCode: 'קוד מדינה',
    department: 'מחלקה',
    address: 'כתובת',
    employeeType: 'סוג עובד',
    business: 'עסק',
    createdAt: 'הוקם בתאריך',
    factory: 'מפעל',
    organization: 'ארגון',
    kupatHulim: 'קופת חולים',
    workEmail: 'דוא"ל עבודה',
    alliyaDate: 'תאריך עלייה',

    gender: 'מין',
    maritalStatus: 'מצב משפחתי',
    employmentEndDate: 'תאריך סיום עסקה',
    manager: 'מנהל',
    customFieldsV2: 'שדות מותאמים אישית',
    onboarding: 'תהליך קליטה',
    bankDetails: 'פרטי בנק',
};

export const EmployeeTypes = [
    { value: EEmployeeType.FULL_TIME, label: 'עובד קבוע' },
    { value: EEmployeeType.PART_TIME, label: 'עובד חלקי' },
    { value: EEmployeeType.TEMPORARY, label: 'עובד זמני' },
    { value: EEmployeeType.CONTRACT, label: 'עובד קבלן' },
    { value: EEmployeeType.FOREIGN_FULL_TIME, label: 'עובד זר קבוע' },
    { value: EEmployeeType.FOREIGN_TEMPORARY, label: 'עובד זר זמני' },
    { value: EEmployeeType.OTHER, label: 'אחר' },
];
export const AppendsToHeb = {
    sugHacnacaFile1: 'סוג הכנסה 1',
    sugHacnacaFile2: 'סוג הכנסה 2',
    sugHacnacaFile3: 'סוג הכנסה 3',
    teudaFile: 'תעודת שחרור',
    academicDegreeFile: 'תעודת אקדמייה',
    alimonyFile: ' תעודת מזונות',
    blindFile: 'תעודת עיוור',
    blindExtraFile: 'אסמכתא תגמול חודשי לפי חוק הנכים או פעולות איבה',

    diasbiltyKidFile: 'תעודת ילד בעל צרכים מיוחדים',

    employeeIdAppends: 'תעודת זהות',
    employeeIdTypeBio1Image: 'ת.ז ביומטרית -צד 1',
    employeeIdTypeBio2Image: 'ת.ז ביומטרית -צד 2',
    employeeIdTypeRegular1Image: 'ת.ז רגילה',

    noIncomeFile: 'תעודת חוסר הכנסה',
    oleFile: 'תעודת עולה חדש',
    employeeBankInfo: 'אישור ניהול חשבון',
    pkidShumaFile: 'פקיד שומה',
    psakDinFile: 'פסק דין',
    spouseFile: 'תעודת בן-זוג',
    yeshuvFile: 'תעודת יישוב',

    seperateFile: 'תעודת גירושין',
    separatedAppends: 'תעודת גירושין',

    disabilityOrBlindAppends: 'תעודת נכות או עיוור',
    permanentResidentAppends: 'אישור יישוב מזכה',
    newImmigrantAppends: 'תעודת עולה חדש',
    childrenListedInPartCAndIProvideForThemAppends: 'אישור תשלום מזונות',
    parentToDisabledChildrenAppends: 'אישור ילדים עם צרכים מיוחדים',
    alimonyForMyFormerSpouseAppends: 'אישור תשלום מזונות לבן זוג קודם',
    dischargedSoliderOrNationalServiceAppends: 'תעודת שחרור',
    academicDegreeOrInternshipAppends: 'תואר אקדמי או תעודת השלמת התמחות',
    taxCoordinationReasonAppends: 'סיבת תיאום מס',
    taxCoordinationOtherIncomeAppends: 'תלוש הכנסה נוספת לתיאום מס',
};

export const EmployeeTypesNew = [
    EEmployeeType.FULL_TIME,
    EEmployeeType.PART_TIME,
    EEmployeeType.TEMPORARY,
    EEmployeeType.CONTRACT,
    EEmployeeType.FOREIGN_FULL_TIME,
    EEmployeeType.FOREIGN_TEMPORARY,
    EEmployeeType.OTHER,
];

export const absenceTypesArr = [
    { value: EAttendanceOptions.VACATION, label: 'חופש' },
    { value: EAttendanceOptions.SICK, label: 'מחלה' },
    { value: EAttendanceOptions.MILITARY, label: 'מילואים' },
    { value: EAttendanceOptions.HOLIDAY, label: 'חג' },
    { value: EAttendanceOptions.GROUP, label: 'יותר מיום' },
    { value: EAttendanceOptions.PREGNANCY, label: 'ח. לידה' },
    { value: EAttendanceOptions.DEATH, label: 'אבל' },
    { value: EAttendanceOptions.OTHER, label: 'אחר' },
];

export const EmployeeStateToHeb = {
    [EEmployeeState.ACTIVE]: 'פעיל',
    [EEmployeeState.INACTIVE]: 'לא פעיל',
    [EEmployeeState.ONLEAVE]: 'בחופשה',
    [EEmployeeState.HEARING]: 'בשימוע',
    [EEmployeeState.ONBOARDING]: 'בקליטה',
    [EEmployeeState.OFFBOARDING]: 'בעזיבה',
    [EEmployeeState.SUSPENDED]: 'מושעה',
};

export const ATTACHMENT_STATE_LIST = [State.EMPLOYER_REJECTED, State.APPROVED];
