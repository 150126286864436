import { HttpContext } from '@angular/common/http';
import { LOADING_CONTEXT } from '../tokens/http-loading.token';
import { EB360Lang } from './shared.enums';
import {
    IB360ConfirmDialogData,
    IB360Language,
    ILoadingContext,
} from './shared.interfaces';

export function withLoadingTracking(options: Partial<ILoadingContext> = {}) {
    // Always enforce track: true, but allow specifying other properties
    const mergedOptions: ILoadingContext = {
        track: true,
        ...options,
    };

    return {
        context: new HttpContext().set(LOADING_CONTEXT, mergedOptions),
    };
}

export const B360_GENERIC_DELETE_CONFIRM_DIALOG_DATA: IB360ConfirmDialogData = {
    buttons: [
        {
            text: 'actions.approve',
            color: 'warn',
            data: true,
        },
        {
            text: 'actions.cancel',
            color: 'accent',

            data: false,
        },
    ],
    title: 'actions.delete',

    titleIcon: 'question_mark',
    message: 'warning.notReversible',
};

export const B360_GENERIC_DELETE_CONFIRM_WITH_REASON_DIALOG_DATA: IB360ConfirmDialogData =
    {
        buttons: [
            {
                text: 'actions.approve',
                color: 'warn',
                data: true,
            },
            {
                text: 'actions.cancel',
                color: 'accent',

                data: false,
            },
        ],
        title: 'actions.delete',
        withComment: true,
        commentLabel: 'words.reasonForDeletion',
        needTextApproval: true,

        titleIcon: 'question_mark',
        message: 'warning.notReversible',
    };

export const B360_GENERIC_DELETE_CONFIRM_DIALOG_DATA_WITH_APPROVAL: IB360ConfirmDialogData =
    {
        buttons: [
            {
                text: 'actions.approve',
                color: 'warn',
                data: true,
            },
            {
                text: 'actions.cancel',
                color: 'accent',

                data: false,
            },
        ],
        title: 'actions.delete',

        titleIcon: 'question_mark',
        message: 'warning.notReversible',
        needTextApproval: true,
    };

export const B360_GENERIC_CONFIRM_DIALOG_DATA_WITH_APPROVAL: IB360ConfirmDialogData =
    {
        buttons: [
            {
                text: 'actions.approve',
                color: 'warn',
                data: true,
            },
            {
                text: 'actions.cancel',
                color: 'accent',

                data: false,
            },
        ],
        title: 'actions.confirmAction',

        titleIcon: 'question_mark',
        message: 'warning.areYouSure',
        needTextApproval: true,
    };
export const B360_GENERIC_CONFIRM_DIALOG: IB360ConfirmDialogData = {
    buttons: [
        {
            text: 'actions.approve',
            color: 'warn',
            data: true,
        },
        {
            text: 'actions.cancel',
            color: 'accent',

            data: false,
        },
    ],
    title: 'actions.confirmAction',

    titleIcon: 'question_mark',
    message: 'warning.areYouSure',
};

export const B360_SNACK_MSG = {
    success: {
        deleteSelected: 'snack.success.deleteSelected',
        deleteOne: 'snack.success.deleteOne',
        general: 'snack.success.general',
        addedEmployees: 'snack.success.addedEmployees',
        delete: 'snack.success.delete',
        processingKeepWorking: 'snack.success.processingKeepWorking',
        uploadingPayslipsCompleted: 'snack.success.uploadingPayslipsCompleted',
    },
    error: {
        nothingSelected: 'snack.error.nothingSelected',
        loadingError: 'snack.error.loadingError',
        deleteOne: 'snack.error.deleteOne',
        invalidForm: 'snack.error.invalidForm',
        noFile: 'snack.error.noFile',
        general: 'snack.error.general',
        maxItemsAllowed: 'snack.error.maxItemsAllowed',
        cantDeletePositionField: 'snack.error.cantDeletePositionField',
        noEmployeeId: 'snack.error.noEmployeeId',
        duplicatedIdNumber: 'snack.error.sameIdNumberMoreThanOnce',
        apiError: 'snack.error.apiError',
        existInList: 'snack.error.existInList',
        noFiltersFound: 'snack.error.noFiltersFound',
        gpsError: 'snack.error.gpsError',
        onlyApprovedDocsAllowed: 'snack.error.onlyApprovedDocsAllowed',
        fileTypeNotSupported: 'snack.error.fileTypeNotSupported',
        canOnlyApproveEmployerPending:
            'snack.error.canOnlyApproveEmployerPending',
    },
};

export const B360_LANGUAGES: IB360Language[] = [
    { text: 'עברית', type: EB360Lang.HEBREW, dir: 'rtl' },
    { text: 'English', type: EB360Lang.ENGLISH, dir: 'ltr' },
    { text: 'አማርኛ', type: EB360Lang.AMHARIC, dir: 'ltr' },
    { text: 'русский', type: EB360Lang.RUSSIAN, dir: 'ltr' },
    { text: 'عربيه', type: EB360Lang.ARABIC, dir: 'rtl' },
    { text: 'Français', type: EB360Lang.FRENCH, dir: 'ltr' },
    { text: 'Español', type: EB360Lang.SPANISH, dir: 'ltr' },
];
