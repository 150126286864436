import { Injectable, inject } from '@angular/core';
import { BiskitsApi } from '@src/app/shared/services/biskits.api';
import { Observable, shareReplay } from 'rxjs';
import { IAgent, WithId } from '../agency.shared.interfaces';

@Injectable({ providedIn: 'root' })
export class AgentService {
    private biskitsApi = inject(BiskitsApi);

    private get _apiUrl() {
        return this.biskitsApi.apiUrl + '/api/agent';
    }

    private _agentProfile: Observable<IAgent>;
    getAgentProfile() {
        if (this._agentProfile) return this._agentProfile;

        this._agentProfile = this.biskitsApi
            .obsGet(`${this._apiUrl}/profile`)
            .pipe(shareReplay(1));

        return this._agentProfile;
    }

    getAgentsTableData(sliderState: boolean): Observable<WithId<IAgent>[]> {
        return this.biskitsApi.obsGet(`${this._apiUrl}/agentsTable`, {
            params: { sliderState },
        });
    }

    createAgent(agent: WithId<IAgent>): Observable<WithId<IAgent>> {
        return this.biskitsApi.obsPost(`${this._apiUrl}`, agent);
    }

    updateAgent(agent: WithId<IAgent>): Observable<WithId<IAgent>> {
        return this.biskitsApi.obsPatch(`${this._apiUrl}`, agent);
    }

    clearAgentProfile(){
        this._agentProfile = null;
    }
}
