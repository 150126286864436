import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class FireBaseService {
    // biskitsApi = inject(BiskitsApi);
    // // private readonly _messaging = inject(Messaging);
    // async init() {
    //     const tk = await this.tokenInit();
    //     if (tk) {
    //         // register token and gleap user
    //         this._registerToGleapPushTopics(tk);
    //         this.addFcmTokenToUser(tk);
    //     } else {
    //         this.requestPermission();
    //     }
    // }
    // /**
    //  * @description socket registration for gleap push topics, send to server to register
    //  */
    // private _registerToGleapPushTopics(token: string) {
    //     // Gleap.on('register-pushmessage-group', async (topic) => {
    //     //     return await this.biskitsApi.post(`${API}/fcm/registerUserToGleapTopic`, { topic, token });
    //     // })
    //     // Gleap.on('unregister-pushmessage-group', async (topic) => {
    //     //     return await this.biskitsApi.post(`${API}/fcm/unRegisterUserToGleapTopic`, { topic, token });
    //     // })
    //     return;
    // }
    // async tokenInit(sw: ServiceWorkerRegistration | null = null) {
    //     try {
    //         return getToken(this._messaging, {
    //             vapidKey: environment.VAPID,
    //         });
    //     } catch (error) {
    //         console.error(error);
    //     }
    // }
    // requestPermission() {
    //     if ('Notification' in window) {
    //         Notification.requestPermission().then(async (permission) => {
    //             if (permission === 'granted') {
    //                 const tk = await this.tokenInit();
    //                 // save to db
    //                 this.addFcmTokenToUser(tk);
    //                 return tk;
    //             } else if (permission === 'denied') {
    //                 // update user
    //                 this.userRejectedFcm();
    //             }
    //         });
    //     }
    // }
    // /**
    //  * @description add fcm token to user
    //  * @param token
    //  * @returns void
    //  */
    // addFcmTokenToUser(token: string) {
    //     return this.biskitsApi
    //         .obsPatch(`${API}/fcm/addFcmToken`, { token })
    //         .pipe(take(1))
    //         .subscribe();
    // }
    // /**
    //  * @description add fcm token to user
    //  * @param token
    //  * @returns void
    //  */
    // removeFcmTokenFromUser(token: string) {
    //     return this.biskitsApi
    //         .obsPatch(`${API}/fcm/removeFcmToken`, { token })
    //         .pipe(take(1))
    //         .subscribe();
    // }
    // /**
    //  * @description update user rejection fcm and date
    //  */
    // userRejectedFcm() {
    //     return this.biskitsApi
    //         .obsPatch(`${API}/fcm/userRejectedFcm`, {})
    //         .pipe(take(1))
    //         .subscribe();
    // }
}
