import { Directionality } from '@angular/cdk/bidi';
import { DOCUMENT } from '@angular/common';
import { Injectable, inject } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { EB360LocalDbs } from '@src/app/localDB';
import { NgxIndexedDBService } from 'ngx-indexed-db';
import { BehaviorSubject, switchMap, take } from 'rxjs';
import { IB360Language } from '../helpers/shared.interfaces';

@Injectable({
    providedIn: 'root',
})
export class LanguageService {
    private dbService = inject(NgxIndexedDBService);
    private translateService = inject(TranslateService);
    private directionality = inject(Directionality);
    private document = inject(DOCUMENT);

    private _currLang$ = new BehaviorSubject<IB360Language>(null);

    get currLang$() {
        return this._currLang$.asObservable();
    }

    /**
     * @description set initial language and dir without updating indexedDB
     */
    set initialLang(lang: IB360Language) {
        this.translateService.use(lang.type);
        this.dir = lang.dir;

        this._currLang$.next(lang);
        this._setGleapFeedbackDirOnChange(lang.dir);
    }

    set dir(dir: 'rtl' | 'ltr') {
        this.document.body.setAttribute('dir', dir);
        this.document.documentElement.setAttribute('dir', dir);
        this.directionality.change.emit(dir);
    }

    private _setGleapFeedbackDirOnChange(dir: 'ltr' | 'rtl') {
        const feedbackButton = document.querySelector(
            '.bb-feedback-button',
        ) as HTMLElement;

        if (!feedbackButton) return;

        feedbackButton.setAttribute('dir', dir);

        // Set up a MutationObserver to watch for attribute changes.
        const observer = new MutationObserver((mutations) => {
            for (const mutation of mutations) {
                if (
                    mutation.type === 'attributes' &&
                    mutation.attributeName === 'dir'
                ) {
                    // Gleap changed it. Set it back to desired dir.
                    feedbackButton.setAttribute('dir', dir);

                    // Now that we've corrected it once, disconnect the observer so it doesn't run again.
                    observer.disconnect();
                    break;
                }
            }
        });

        observer.observe(feedbackButton, { attributes: true });
    }

    /**
     * @description set language and dir in indexedDB after app init
     */
    setLanguage(langData: IB360Language) {
        // validate that lang is not the same as current lang
        const currLang = this.translateService.currentLang;
        if (currLang === langData.type) return;

        // update lang and dir in indexedDB
        this.dbService
            .getByKey(EB360LocalDbs.GENERAL_DATA, 'USER_AGENT')
            .pipe(
                switchMap((res: any) => {
                    if (res.lang === langData.type) return;

                    // update translate
                    this.translateService.use(langData.type);

                    // set new lang and dir
                    this.dir = langData.dir;

                    this._currLang$.next(langData);

                    // update Gleap feedback dir if needed
                    this._setGleapFeedbackDirOnChange(langData.dir);

                    return this.dbService.update(EB360LocalDbs.GENERAL_DATA, {
                        id: 'USER_AGENT',
                        dir: langData.dir,
                        lang: langData.type,
                    });
                }),
                take(1),
            )
            .subscribe();
    }
}
