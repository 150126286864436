import { inject } from '@angular/core';
import { Router } from '@angular/router';
import { DeviceDetectorService } from 'ngx-device-detector';
import { Observable, map, of, take } from 'rxjs';
import { IAgent } from './Agency/agency.shared.interfaces';
import { AgentService } from './Agency/services/agent.service';
import { AuthService } from './Auth/auth.service';
import { Role } from './shared/helpers/shared.enums';
import { UserProfile } from './shared/helpers/shared.interfaces';
import { UserService } from './shared/services/user.service';

export function HasNoTokenGuard() {
    const token = localStorage.getItem('token');

    console.log('HasNoTokenGuard', token);
    if (!token) return true;
    // navigate by token
    else return resolveWhereToNav();
}

export function HasTokenGuard(): boolean {
    const token = localStorage.getItem('token');
    const router = inject(Router);

    if (token) return true;
    else {
        router.navigate(['./auth']);
        return false;
    }
}

/**
 * @description Resolve where to navigate if token is present
 * @returns false in order to not allow nav to auth + route to the correct module
 */
export function resolveWhereToNav() {
    const api = inject(UserService);
    const authService = inject(AuthService);
    const router = inject(Router);
    const deviceService = inject(DeviceDetectorService);

    // if (profile?.permission?.role === Role.ADMIN) return of(true);

    api.getProfile()
        .pipe(
            take(1),
            map((p: UserProfile) => {
                console.log('resolveWhereToNav', p);
                // no profile logout
                if (!p) {
                    authService.logout('isAdminGuard');
                    return false;
                }

                if (typeof p === 'string' && p === 'agent') {
                    router.navigate(['./agency']);
                    return false;
                }

                const role = p?.permission?.role ?? null;

                // if mobile then only employee
                if (
                    deviceService.isMobile() ||
                    deviceService.isTablet() ||
                    !role
                ) {
                    router.navigate(['./employee/dashboard']);
                    return false;
                } else if (role === Role.ADMIN) {
                    router.navigate(['./admin/dashboard']);
                    return false;
                } else if (role) {
                    router.navigate(['./business/dashboard']);
                    return false;
                }

                return false;
            }),
        )
        .subscribe();

    return false;
}

export function NoMobileGuard(): boolean {
    const deviceService = inject(DeviceDetectorService);
    return !deviceService.isMobile() && !deviceService.isTablet();
}

export function isAdminGuard(): Observable<boolean> {
    const api = inject(UserService);
    const authService = inject(AuthService);

    const profile = api.profileValue;
    if (profile?.permission?.role === Role.ADMIN) return of(true);

    return api.getProfile().pipe(
        map((p: UserProfile) => {
            if (!p) {
                // try to get profile again
                authService.logout('isAdminGuard');
                return false;
            } else if (p?.permission?.role === Role.ADMIN) return true;
            else false;
        }),
    );
}

export function isBusinessGuard(): Observable<boolean> {
    const api = inject(UserService);
    const authService = inject(AuthService);

    const profile = api.profileValue;
    if (profile?.permission?.role) return of(true);

    return api.getProfile().pipe(
        map((p: UserProfile) => {
            if (!p) {
                authService.logout('isBusinessGuard');
                return false;
            } else if (p?.permission?.role) return true;
            else return false;
        }),
    );
}

export function isAgentGuard(): Observable<boolean> {
    const api = inject(AgentService);
    const authService = inject(AuthService);

    return api.getAgentProfile().pipe(
        map((p: IAgent) => {
            if (!p) {
                authService.logout('isAgentGuard');
                return false;
            } else true;
        }),
    );
}

export function isEmployeeGuard() {
    const api = inject(UserService);
    const authService = inject(AuthService);

    return api.getProfile().pipe(
        map((p: UserProfile) => {
            if (!p) authService.logout('isEmployeeGuard');
            else true;
        }),
    );
}
