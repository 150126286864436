import { Injectable, signal } from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class LoadingService {
    // For Angular 18 Signals:
    private _loadingCount = signal(0);

    public isLoading = signal(false);

    startLoading(initiator?: string) {
        this._loadingCount.update((count) => count + 1);
        this.updateIsLoading();
    }

    stopLoading(initiator?: string) {
        this._loadingCount.update((count) => Math.max(count - 1, 0));

        this.updateIsLoading();
    }

    private updateIsLoading() {
        this.isLoading.set(this._loadingCount() > 0);
    }
}
