import { IEmployee, IEmployeeFieldMapping } from './shared.interfaces';
import { WithEmployeeObject, WithEmployeeString } from './types';

export abstract class B360Utils {
    // Implementation
    static populateWithEmployeeData<T extends object>(
        data: WithEmployeeString<T>[],
        emps: IEmployee[],
        employeeIdFieldOrArray?:
            | keyof WithEmployeeString<T>
            | IEmployeeFieldMapping[],
    ): WithEmployeeObject<T>[] {
        // 1. Create a dictionary of employees keyed by id (or some unique key)
        //    so we can do O(1) lookups.
        const empDict = new Map<string, IEmployee>();
        for (const emp of emps) {
            empDict.set(emp._id, emp);
        }

        // 2. Then, inside your replaceEmployee or replaceEmployeeMultipleFields,
        //    replace the linear search with a simple dictionary (Map) lookup:
        //    const matchedEmp = empDict.get(employeeId);

        if (!employeeIdFieldOrArray) {
            return data.map((item) =>
                this.replaceEmployee(item, empDict, 'employee'),
            );
        }

        if (Array.isArray(employeeIdFieldOrArray)) {
            const fieldsArray = employeeIdFieldOrArray;
            return data.map((item) =>
                this.replaceEmployeeMultipleFields(item, empDict, fieldsArray),
            );
        }

        return data.map((item) =>
            this.replaceEmployee(item, empDict, employeeIdFieldOrArray),
        );
    }

    static B360customFilterPredicate() {
        return (data: any, filter: string): boolean => {
            const accumulator = (currentTerm, key) => {
                return this._flattenData(currentTerm, data[key]);
            };
            const dataStr = Object.keys(data)
                .reduce(accumulator, '')
                .toUpperCase();

            return dataStr.indexOf(filter) !== -1;
        };
    }

    /**
     * Normalizes text across multiple languages by:
     *  - Using NFD to separate accent marks
     *  - Removing diacritical marks
     *  - Removing punctuation/symbols
     *  - Converting to uppercase
     * This helps ensure a uniform comparison for partial matches.
     */
    static normalizeString(str: string): string {
        if (!str) return '';
        return (
            str
                // 1) Decompose combined characters (e.g., "ñ" => "n" + "~")
                .normalize('NFD')
                // 2) Remove combining diacritical marks
                .replace(/[\u0300-\u036f]/g, '')
                // 3) Remove punctuation/symbols but keep letters (all langs), numbers, and whitespace
                .replace(/[^\p{L}\p{N}\s]/gu, '')
                // 4) Convert everything to uppercase
                .toUpperCase()
                // 5) Trim
                .trim()
        );
    }

    /**
     * Determines if `candidate` is "fuzzy close enough" to `target`.
     * Uses Levenshtein distance with a maximum allowed distance of 10%.
     */
    static isFuzzyMatch(candidate: string, target: string): boolean {
        // Quick exact match check
        if (candidate === target) {
            return true;
        }

        // For shorter words (<5 chars), do an exact match
        const minFuzzyLength = 5;
        if (target.length < minFuzzyLength) {
            return candidate === target;
        }

        // For words >= 5 chars, allow up to ~10% distance
        const allowedDistance = Math.floor(target.length * 0.2);
        const distance = B360Utils.levenshteinDistance(candidate, target);

        return distance <= allowedDistance;
    }

    /**
     * Calculate the Levenshtein distance between two strings.
     * For large data, consider a more optimal approach or caching.
     */
    static levenshteinDistance(a: string, b: string): number {
        const matrix = [];

        // Initialize the first row and column
        for (let i = 0; i <= b.length; i++) {
            matrix[i] = [i];
        }
        for (let j = 0; j <= a.length; j++) {
            matrix[0][j] = j;
        }

        // Compute distances
        for (let i = 1; i <= b.length; i++) {
            for (let j = 1; j <= a.length; j++) {
                if (b.charAt(i - 1) === a.charAt(j - 1)) {
                    matrix[i][j] = matrix[i - 1][j - 1];
                } else {
                    matrix[i][j] = Math.min(
                        matrix[i - 1][j] + 1, // deletion
                        matrix[i][j - 1] + 1, // insertion
                        matrix[i - 1][j - 1] + 1, // substitution
                    );
                }
            }
        }

        return matrix[b.length][a.length];
    }

    static B360SearchableStringFilterPredicate() {
        return (data: any, filter: string): boolean => {
            // If this row has no searchable string, skip:
            if (!data._searchableString) return false;

            // If filter is empty, show all rows:
            if (!filter) return true;

            // 1) Normalize the user’s filter (remove punctuation, diacritics, uppercase)
            const normalizedFilter = B360Utils.normalizeString(filter);
            // 2) Convert the filter string into tokens (split by whitespace)
            const filterTokens = normalizedFilter.split(/\s+/).filter(Boolean);

            // If user typed only punctuation or spaces => treat as no filter
            if (filterTokens.length === 0) {
                return true;
            }

            // 3) Normalize the data’s searchable string
            const normalizedDataString = B360Utils.normalizeString(
                data._searchableString,
            );
            // 4) Tokenize the data by whitespace
            const dataTokens = normalizedDataString
                .split(/\s+/)
                .filter(Boolean);

            // 5) For each user token, check if *any* data token contains it (substring match)
            return filterTokens.every((userToken) => {
                return dataTokens.some((docToken) =>
                    docToken.includes(userToken),
                );
            });
        };
    }

    private static _flattenData(result: string, value: any): string {
        if (typeof value === 'object' && value !== null) {
            if (Array.isArray(value)) {
                // Process array elements
                value.forEach((item) => {
                    result = this._flattenData(result, item);
                });
            } else {
                // Process object properties
                Object.values(value).forEach((val) => {
                    result = this._flattenData(result, val);
                });
            }
        } else {
            // Concatenate value if it's not an object or array
            result += `${value} `;
        }
        return result;
    }

    private static replaceEmployeeMultipleFields<T extends object>(
        item: WithEmployeeString<T>,
        empDict: Map<string, IEmployee>, // changed from `emps: IEmployee[]` to a lookup map
        fields: IEmployeeFieldMapping[],
    ): WithEmployeeObject<T> {
        // Make a shallow copy of the item to avoid mutating the original
        const newItem = { ...item } as any;

        for (const { fieldNameOnData, populateAs } of fields) {
            const employeeId = newItem[fieldNameOnData];

            // Only do a lookup if employeeId is a string
            if (typeof employeeId === 'string') {
                // O(1) lookup with Map instead of .find(...)
                const foundEmployee = empDict.get(employeeId);

                // Assign either the found employee object or the original string if not found
                newItem[populateAs] = foundEmployee ?? employeeId;
            } else {
                // In case it's not a string, just assign the original value
                newItem[populateAs] = employeeId;
            }
        }

        // Cast back to your desired type
        return newItem as WithEmployeeObject<T>;
    }

    // Helper method to replace the employee string with the employee object
    private static replaceEmployee<T extends object>(
        item: WithEmployeeString<T>,
        empDict: Map<string, IEmployee>, // changed from emps array to a lookup map
        fieldName: keyof WithEmployeeString<T>,
    ): WithEmployeeObject<T> {
        // 1. Extract the employee ID from the given field (e.g., "employee")
        const employeeId = item[fieldName];

        // 2. Attempt to look up that ID in the map
        let matchedEmp: IEmployee | undefined;
        if (typeof employeeId === 'string') {
            matchedEmp = empDict.get(employeeId);
        }

        // 3. If "manager" is present and is a string, attempt to find their employee object
        let managerEmp: IEmployee | undefined;
        if ('manager' in item && typeof item.manager === 'string') {
            managerEmp = empDict.get(item.manager);
        }

        // 4. Construct and return the new object
        return {
            ...item,
            // `employee` is always replaced with the matched employee object (or remain a string if not found)
            employee: matchedEmp ?? item[fieldName],
            // If we found a manager, replace the string with the manager object; otherwise, leave it alone
            ...(managerEmp !== undefined
                ? { manager: managerEmp ?? item.manager }
                : {}),
        } as WithEmployeeObject<T>;
    }

    // Utility Function to convert object to string including nested objects
    static objectToString(obj: any): string {
        let str = '';

        for (const key in obj) {
            if (typeof obj[key] === 'object') {
                str += B360Utils.objectToString(obj[key]);
            } else {
                str += obj[key];
            }
        }

        return str.toUpperCase();
    }

    static tryToCall(phoneNumber: string) {
        if (!phoneNumber) {
            return;
        }

        const phoneNumberFormatted = phoneNumber.replace(/\D/g, '');
        if (phoneNumberFormatted.length < 10) {
            return;
        }
        const phoneNumberLink = `tel:${phoneNumberFormatted}`;
        window.open(phoneNumberLink, '_self');
    }

    static tryToSendEmail(email: string) {
        if (!email) {
            return;
        }
        const emailLink = `mailto:${email}`;

        window.open(emailLink, '_self');
    }

    static returnReadableBrowserData() {
        const userAgent = navigator.userAgent;
        const rdBrowserObj = {
            browserType: '',
            browserVersion: '',
        };

        // parse user agent data
        if (userAgent.indexOf('Firefox') > -1) {
            rdBrowserObj.browserType = 'Firefox';
            rdBrowserObj.browserVersion = userAgent.split('Firefox/')[1];
        } else if (
            userAgent.indexOf('Opera') > -1 ||
            userAgent.indexOf('OPR') > -1
        ) {
            rdBrowserObj.browserType = 'Opera';
            rdBrowserObj.browserVersion =
                userAgent.indexOf('OPR') > -1
                    ? userAgent.split('OPR/')[1]
                    : userAgent.split('Opera/')[1];
        } else if (userAgent.indexOf('Trident') > -1) {
            rdBrowserObj.browserType = 'IE';
            rdBrowserObj.browserVersion = userAgent.split('rv:')[1];
        } else if (userAgent.indexOf('Edge') > -1) {
            rdBrowserObj.browserType = 'Edge';
            rdBrowserObj.browserVersion = userAgent.split('Edge/')[1];
        } else if (userAgent.indexOf('Chrome') > -1) {
            rdBrowserObj.browserType = 'Chrome';
            rdBrowserObj.browserVersion = userAgent.split('Chrome/')[1];
        } else if (userAgent.indexOf('Safari') > -1) {
            rdBrowserObj.browserType = 'Safari';
            rdBrowserObj.browserVersion = userAgent.split('Safari/')[1];
        } else {
            rdBrowserObj.browserType = 'Other';
            rdBrowserObj.browserVersion = 'N/A';
        }

        return rdBrowserObj;
    }

    static returnReadableOSAndDeviceData() {
        const userAgent = navigator.userAgent;
        const rdDeviceObj = {
            OS: '',
            deviceType: '',
        };

        // parse user agent data
        if (userAgent.indexOf('Win') > -1) {
            rdDeviceObj.OS = 'Windows';
        } else if (userAgent.indexOf('Mac') > -1) {
            rdDeviceObj.OS = 'Mac OS';
        } else if (userAgent.indexOf('Linux') > -1) {
            rdDeviceObj.OS = 'Linux';
        } else {
            rdDeviceObj.OS = 'Other';
        }

        // device type if it's mobile then OS is MAC OS then return IOS
        // if mobile and not MAC OS then return Android else return Desktop
        if (userAgent.indexOf('Mobile') > -1) {
            if (userAgent.indexOf('Mac') > -1) {
                rdDeviceObj.deviceType = 'IOS';
            } else {
                rdDeviceObj.deviceType = 'Android';
            }
        } else {
            rdDeviceObj.deviceType = 'Desktop';
        }

        return rdDeviceObj;
    }
}

// decorator function
export function B360AutoUnsub() {
    return function (constructor) {
        const orig = constructor.prototype.ngOnDestroy;

        constructor.prototype.ngOnDestroy = function () {
            for (const prop in this) {
                const property = this[prop];
                if (property && typeof property.unsubscribe === 'function') {
                    property.unsubscribe();
                }
            }
            if (orig && typeof orig === 'function') {
                orig.apply(this);
            }
        };
    };
}
