import { Injectable, inject } from '@angular/core';
import { EB360LocalDbs } from '@src/app/localDB';

import { MatDialog } from '@angular/material/dialog';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer, Meta } from '@angular/platform-browser';

import { datadogRum } from '@datadog/browser-rum';
import { iconsMetaData } from '@src/app/icons';
import { RobotsMetaTags } from '@src/app/robots';
import { environment } from '@src/environments/environment';
import { API, AUTH_API } from '@src/main';
import { DeviceDetectorService } from 'ngx-device-detector';
import { NgxIndexedDBService } from 'ngx-indexed-db';
import { switchMap, take } from 'rxjs';
import { B360ConfirmDialog } from '../components/b360-confirm-dialog/b360-confirm-dialog.component';
import {
    B360_GENERIC_CONFIRM_DIALOG,
    B360_LANGUAGES,
} from '../helpers/shared.const';
import { LanguageService } from './language.service';

@Injectable({
    providedIn: 'root',
})
export class InitService {
    private dbService = inject(NgxIndexedDBService);
    private deviceService = inject(DeviceDetectorService);
    private langService = inject(LanguageService);
    private matIconReg = inject(MatIconRegistry);
    private DS = inject(DomSanitizer);
    private dialog = inject(MatDialog);
    private metaTagService = inject(Meta);

    get GMT() {
        return `GMT${new Date().getTimezoneOffset() <= 0 ? '+' : '-'}${Math.abs(
            new Date().getTimezoneOffset() / 60,
        )
            .toString()
            .padStart(2, '0')}:00`;
    }

    get defaultLang() {
        return 'he';
    }

    get rtlLanguages() {
        return ['ar', 'he', 'fa', 'ur'];
    }

    get dir() {
        return this.rtlLanguages.includes(navigator.language.split('-')[0])
            ? 'rtl'
            : 'ltr';
    }

    get deviceInfo() {
        return this.deviceService.getDeviceInfo();
    }

    get locale() {
        return navigator.language.split('-')[0];
    }

    initData() {
        this.metaTagService.addTags(RobotsMetaTags);

        // // add data dog logs
        this._initDataDog();

        // add svg icons to mat-icon
        this.addCustomIconsToMatIcons();

        // check compatibility with ios version
        this.checkIosVersion();

        // updating USER GENERAL DATA (dir, locale, lang)
        this.dbService
            .getByKey(EB360LocalDbs.GENERAL_DATA, 'USER_AGENT')
            .pipe(
                switchMap((res: any) => {
                    const currLang = res?.lang ?? this.defaultLang;

                    const LANG_DATA = B360_LANGUAGES.find((lang) => {
                        return lang.type === currLang;
                    });

                    this.langService.initialLang = LANG_DATA;

                    return this.dbService.update(EB360LocalDbs.GENERAL_DATA, {
                        id: 'USER_AGENT',
                        locale: this.locale,
                        lang: LANG_DATA.type,
                        dir: LANG_DATA.dir,
                        GMT: this.GMT,
                        isMobile: this.deviceService.isMobile(),
                        ...this.deviceInfo,
                    });
                }),
                take(1),
            )
            .subscribe();
    }

    addCustomIconsToMatIcons() {
        for (const icon of iconsMetaData) {
            this.matIconReg.addSvgIcon(
                icon.iconName,
                this.DS.bypassSecurityTrustResourceUrl(icon.src),
            );
        }
    }

    /**
     * @description DataDog RUM initialization
     */
    private _initDataDog() {
        if (environment.production) {
            datadogRum.init({
                applicationId: '1964a71d-6898-4b52-8a11-e82cfa5c7ad0',
                clientToken: 'pub14ee035aba76d254331a8e9793f70800',
                site: 'datadoghq.com',
                service: 'b360',
                version: environment.appVersion,
                env: 'production',
                sessionSampleRate: 100,
                sessionReplaySampleRate: 100,
                telemetrySampleRate: 100,
                trackUserInteractions: true,
                trackResources: false,
                trackLongTasks: false,
                compressIntakeRequests: true,
                defaultPrivacyLevel: 'allow',
                allowedTracingUrls: [
                    (url) => {
                        return (
                            url.startsWith(`${API}`) ||
                            url.startsWith(`${AUTH_API}`)
                        );
                    },
                ],
            });
        }
    }

    checkIosVersion() {
        const iOSVersion = getIOSVersion(navigator.userAgent);

        if (iOSVersion && iOSVersion < 16) {
            this.dialog
                .open(B360ConfirmDialog, {
                    width: '350px',
                    panelClass: 'b360ConfirmDialog',
                    data: {
                        ...B360_GENERIC_CONFIRM_DIALOG,
                        title: 'iosVersionErrorTitle',
                        message: 'iosVersionErrorNeedUpdateMessage',
                        confirmButtonText: 'אישור',
                    },
                    autoFocus: false,
                })
                .afterClosed();
            console.log('iOS version is lower than 16', iOSVersion);
        }
    }
}

// Utility function to detect iOS version
function getIOSVersion(ua: string) {
    if (/iPad|iPhone|iPod/.test(ua)) {
        const v = ua.match(/OS (\d+)_?(\d+)?_?(\d+)? like Mac OS X/);
        return v ? parseInt(v[1], 10) : null;
    }
    return null;
}
