{
  "name": "b360-app",
  "version": "8.0.48",
  "scripts": {
    "analyze": "webpack-bundle-analyzer dist/b360/stats.json",
    "ng": "ng",
    "build": "ng build --configuration production",
    "buildpg": "ng build --configuration playground",
    "buildlocal": "ng build --configuration local",
    "build-with-stats": "ng build --configuration production --stats-json ",
    "live": "http-server dist/b360 ",
    "test": "ng test",
    "lint": "ng lint",
    "e2e": "ng e2e",
    "deploy": "bash ./scripts/deploy-production.sh",
    "deploypg": "bash ./scripts/deploy-playground.sh",
    "ionic:build": "npm run build"
  },
  "private": true,
  "dependencies": {
    "@angular/animations": "19.1.1",
    "@angular/cdk": "^19.1.0",
    "@angular/common": "19.1.1",
    "@angular/compiler": "19.1.1",
    "@angular/core": "19.1.1",
    "@angular/fire": "^19.0.0",
    "@angular/forms": "19.1.1",
    "@angular/google-maps": "^19.1.0",
    "@angular/localize": "19.1.1",
    "@angular/material": "^19.1.0",
    "@angular/material-moment-adapter": "^19.1.0",
    "@angular/platform-browser": "19.1.1",
    "@angular/platform-browser-dynamic": "19.1.1",
    "@angular/router": "19.1.1",
    "@angular/service-worker": "^19.1.1",
    "@colsen1991/ngx-translate-extract-marker": "^3.0.1",
    "@datadog/browser-rum": "^6.0.0",
    "@hebcal/core": "^5.8.13",
    "@hebcal/locales": "^6.0.1",
    "@ngrx/component": "^19.0.0",
    "@ngx-translate/core": "^16.0.4",
    "@ngx-translate/http-loader": "^16.0.1",
    "@pdf-lib/fontkit": "^1.1.1",
    "@types/signature_pad": "^2.3.6",
    "ag-grid-angular": "^33.0.4",
    "chart.js": "^4.4.7",
    "chartjs-plugin-datalabels": "^2.2.0",
    "exceljs": "^4.4.0",
    "file-saver": "^2.0.5",
    "gleap": "^14.2.0",
    "hammerjs": "^2.0.8",
    "jszip": "^3.10.1",
    "moment": "^2.30.1",
    "ng-recaptcha": "^13.2.1",
    "ng2-charts": "^8.0.0",
    "ng2-pdf-viewer": "^10.4.0",
    "ngx-device-detector": "^9.0.0",
    "ngx-extended-pdf-viewer": "^22.2.0-alpha.7",
    "ngx-image-compress": "^18.1.5",
    "ngx-image-cropper": "^9.1.1",
    "ngx-indexed-db": "^19.3.1",
    "pdf-lib": "^1.17.1",
    "rxjs": "^7.8.1",
    "signature_pad": "^5.0.4",
    "socket.io-client": "^4.8.1",
    "subsink": "^1.0.2",
    "swiper": "^11.2.1",
    "typescript": "^5.7.3",
    "web-push": "^3.6.7",
    "xlsx": "^0.18.5"
  },
  "devDependencies": {
    "@angular-devkit/build-angular": "^19.1.2",
    "@angular-eslint/builder": "19.0.2",
    "@angular-eslint/eslint-plugin": "19.0.2",
    "@angular-eslint/eslint-plugin-template": "19.0.2",
    "@angular-eslint/schematics": "19.0.2",
    "@angular-eslint/template-parser": "19.0.2",
    "@angular/cli": "^19.1.2",
    "@angular/compiler-cli": "19.1.1",
    "@nrwl/workspace": "^19.8.4",
    "@types/jasmine": "^5.1.5",
    "@types/node": "^22.10.7",
    "@typescript-eslint/eslint-plugin": "^8.20.0",
    "@typescript-eslint/parser": "^8.20.0",
    "eslint": "^9.18.0",
    "ts-node": "~10.9.2",
    "webpack-bundle-analyzer": "^4.10.2"
  }
}
