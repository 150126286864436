import { Routes } from '@angular/router';
import {
    HasNoTokenGuard,
    HasTokenGuard,
    NoMobileGuard,
    isAdminGuard,
    isAgentGuard,
    isBusinessGuard,
    isEmployeeGuard,
} from './app-routing.guard';

export const B360_APP_ROUTES: Routes = [
    {
        path: 'auth',
        title: 'Auth',
        canMatch: [HasNoTokenGuard],
        loadChildren: () => import('./Auth/auth.routing'),
    },
    {
        path: 'employee',
        title: 'Employee',
        canMatch: [HasTokenGuard, isEmployeeGuard],

        loadChildren: () => import('./Employee/employee.routes'),
    },
    {
        path: 'admin',
        title: 'Admin',
        canMatch: [HasTokenGuard, NoMobileGuard, isAdminGuard],
        loadChildren: () => import('./Admin/admin.routing'),
    },
    {
        path: 'business',
        title: 'Business',
        canMatch: [HasTokenGuard, NoMobileGuard, isBusinessGuard],

        loadChildren: () => import('./Business/business.routes'),
    },
    {
        path: 'agency',
        title: 'Agency',
        canMatch: [HasTokenGuard, NoMobileGuard, isAgentGuard],
        loadChildren: () => import('./Agency/agency.routes'),
    },

    {
        path: '**',
        redirectTo: 'auth',
    },
];
