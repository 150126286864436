import {
    HttpEvent,
    HttpHandler,
    HttpInterceptor,
    HttpRequest,
} from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { finalize, Observable } from 'rxjs';
import { LoadingService } from '../services/loading.service';
import { LOADING_CONTEXT } from '../tokens/http-loading.token';

@Injectable()
export class LoadingInterceptor implements HttpInterceptor {
    private loadingService = inject(LoadingService);

    intercept(
        req: HttpRequest<any>,
        next: HttpHandler,
    ): Observable<HttpEvent<any>> {
        // Check if this request should be tracked
        const context = req.context.get(LOADING_CONTEXT);

        if (!context?.track) {
            // If not tracking, just pass through without modifying
            return next.handle(req);
        }

        // If tracking is enabled, start the loader
        this.loadingService.startLoading(context?.fnName);

        return next.handle(req).pipe(
            finalize(() => {
                this.loadingService.stopLoading(context.fnName);
            }),
        );
    }
}
