import { InjectionToken, enableProdMode } from '@angular/core';

import { registerLocaleData } from '@angular/common';
import localeHeExtra from '@angular/common/locales/extra/he';
import localeHe from '@angular/common/locales/he';
import { bootstrapApplication } from '@angular/platform-browser';

import Gleap from 'gleap';
import 'moment/locale/he';
import { register as registerSwiperElments } from 'swiper/element/bundle';
import { AppComponent } from './app/app.component';
import { B360Config } from './app/app.config';
import { environment } from './environments/environment';

// Please make sure to call this method only once!
Gleap.initialize('p1fpPplYoWPY791cg8Q8vG893vFWYWLP');

registerLocaleData(localeHe, localeHeExtra);

registerSwiperElments();

bootstrapApplication(AppComponent, B360Config);

if (environment.production) {
    enableProdMode();
    // window.console.log = () => {};
}

export function loadScript(src: string, callback: () => void): void {
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.async = true;
    script.defer = true; // Ensure the script is deferred
    script.src = src;
    script.onload = callback;
    document.head.appendChild(script);
}

export const API = `${environment.api}/api`;
export const AUTH_API = `${environment.api}/authAPI`;
export const BASE_API = `${environment.api}`;
export const B360_PORTAL_DATA = new InjectionToken<any>('B360PortalData');
