import {
    HttpEvent,
    HttpHandler,
    HttpInterceptor,
    HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { Observable } from 'rxjs';

@Injectable()
export class MomentInterceptor implements HttpInterceptor {
    intercept(
        req: HttpRequest<any>,
        next: HttpHandler,
    ): Observable<HttpEvent<any>> {
        // Clone the request to modify it
        const b = this.convertDatesToUTC(req.body);
        const clonedRequest = req.clone({
            body: b,
        });

        return next.handle(clonedRequest);
    }

    private convertDatesToUTC(body: any): any {
        if (!body || typeof body !== 'object') {
            return body;
        }

        // Check all properties in the body recursively
        for (const key of Object.keys(body)) {
            const value = body[key];

            // If the value is a moment object, convert it to an ISO string
            if (moment.isMoment(value)) {
                body[key] = {
                    date: value.format('YYYY-MM-DD'),
                    time: value.format('HH:mm:ss'),
                    GMT: value.format('Z'),
                };
            }

            // If the value is an object or array, process it recursively
            else if (typeof value === 'object') {
                this.convertDatesToUTC(value);
            }
        }

        return body;
    }
}
