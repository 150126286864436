import {
    HttpClient,
    HttpErrorResponse,
    HttpEvent,
    HttpEventType,
    HttpParams,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { B360HttpOptions } from '../helpers/shared.interfaces';

export enum HTTPMethod {
    GET = 'get',
    POST = 'post',
}

@Injectable({
    providedIn: 'root',
})
export class HttpService {
    constructor(private _http: HttpClient) {}

    oGet(url: string, options: Partial<B360HttpOptions> = {}): Observable<any> {
        // Supporting URL params
        if (options.hasOwnProperty('params')) {
            let params = new HttpParams();
            for (const key in options.params) {
                params = params.append(key, options.params[key]);
            }
            options.params = params;
        }

        return this._http.get(url, options);
    }

    get(url: string, options: Partial<B360HttpOptions> = {}): Promise<any> {
        // Supporting URL params
        if (options.hasOwnProperty('params')) {
            let params = new HttpParams();
            for (const key in options.params) {
                params = params.append(key, options.params[key]);
            }
            options.params = params;
        }

        return new Promise((res) => {
            this._http.get(url, options).subscribe(
                (response) => {
                    res(response);
                },
                (err) => {
                    console.error(`[HttpService] Error retrieving GET ${url}`);
                    res(false);
                    // TODO: remove in prod
                },
            );
        });
    }

    oPostFiles(
        url: string,
        formData: FormData,
        options: Partial<B360HttpOptions> = {},
    ): Observable<any> {
        return this._http.post(url, formData, options);
    }

    oPost(
        url: string,
        data: any,
        options: Partial<B360HttpOptions> = {},
    ): Observable<any> {
        if (Array.isArray(options.files)) {
            const formData: FormData = new FormData();
            for (const item of options.files) {
                formData.append(item.name, item.file, item.file.name);
            }
            data = formData;
        }
        return this._http.post(url, data, options);
    }

    post(
        url: string,
        data: any,
        options: Partial<B360HttpOptions> = {},
    ): Promise<any> {
        if (Array.isArray(options.files)) {
            const formData: FormData = new FormData();
            for (const item of options.files) {
                formData.append(item.name, item.file, item.file.name);
            }
            data = formData;
        }

        return new Promise((res) => {
            this._http.post(url, data, options).subscribe({
                next: (response) => {
                    res(response);
                },
                error: (err) => {
                    console.error(`[HttpService] Error retrieving POST ${url}`);
                    res(false);
                },
            });
        });
    }

    put(
        url: string,
        data: any,
        options: Partial<B360HttpOptions> = {},
    ): Promise<any> {
        if (Array.isArray(options.files)) {
            const formData: FormData = new FormData();
            for (const item of options.files) {
                formData.append(item.name, item.file, item.file.name);
            }
            data = formData;
        }

        return new Promise((res) => {
            this._http.put(url, data, options).subscribe(
                (response) => {
                    res(response);
                },
                (err) => {
                    console.error(`[HttpService] Error retrieving POST ${url}`);
                    res(false);
                    // TODO: remove in prod
                    console.error(err);
                },
            );
        });
    }

    oPatch(
        url: string,
        data: any,
        options: Partial<B360HttpOptions> = {},
    ): Observable<any> {
        // Supporting URL params
        if (Array.isArray(options.files)) {
            const formData: FormData = new FormData();
            for (const item of options.files) {
                formData.append(item.name, item.file, item.file.name);
            }
            data = formData;
        }
        options.body = data;

        return this._http.patch(url, data, options);
    }

    oPut(
        url: string,
        data: any,
        options: B360HttpOptions = {},
    ): Observable<any> {
        // Supporting URL params
        if (Array.isArray(options.files)) {
            const formData: FormData = new FormData();
            for (const item of options.files) {
                formData.append(item.name, item.file, item.file.name);
            }
            data = formData;
        }
        options.body = data;

        return this._http.put(url, data, options);
    }
    patch(
        url: string,
        data: any,
        options: Partial<B360HttpOptions> = {},
    ): Promise<any> {
        if (Array.isArray(options.files)) {
            const formData: FormData = new FormData();
            for (const item of options.files) {
                formData.append(item.name, item.file, item.file.name);
            }
            data = formData;
        }

        return new Promise((res) => {
            this._http.patch(url, data, options).subscribe(
                (response) => {
                    res(response);
                },
                (err) => {
                    console.error(
                        `[HttpService] Error retrieving PATCH ${url}`,
                    );
                    res(false);
                    // TODO: remove in prod
                    console.error(err);
                },
            );
        });
    }

    delete(
        url: string,
        data: any,
        options: Partial<B360HttpOptions> = {},
    ): Promise<any> {
        if (Array.isArray(options.files)) {
            const formData: FormData = new FormData();
            for (const item of options.files) {
                formData.append(item.name, item.file, item.file.name);
            }
            data = formData;
        }
        options.body = data;
        return new Promise((res) => {
            this._http.delete(url, options).subscribe(
                (response) => {
                    res(response);
                },
                (err) => {
                    console.error(`[HttpService] Error retrieving POST ${url}`);
                    res(false);
                    // TODO: remove in prod
                    console.error(err);
                },
            );
        });
    }
    oDelete(
        url: string,
        data: any,
        options: Partial<B360HttpOptions> = {},
    ): Observable<any> {
        // Supporting URL params
        if (Array.isArray(options.files)) {
            const formData: FormData = new FormData();
            for (const item of options.files) {
                formData.append(item.name, item.file, item.file.name);
            }
            data = formData;
        }
        options.body = data;

        return this._http.delete(url, options);
    }

    fileUpload(url: string, data: any): Promise<any> {
        return new Promise((resolve, reject) => {
            this._http
                .post(url, data, {
                    withCredentials: true,
                    reportProgress: true,
                    observe: 'events',
                })
                .pipe(catchError(this.errorMgmt))
                .subscribe({
                    next: (event: HttpEvent<any>) => {
                        switch (event.type) {
                            case HttpEventType.Sent:
                                break;
                            case HttpEventType.ResponseHeader:
                                break;
                            case HttpEventType.UploadProgress:
                                // this.fileUpload = true;
                                const temp = Math.round(
                                    (event.loaded / event.total) * 100,
                                );
                                break;
                            case HttpEventType.Response:
                                const success = true;
                                return resolve(event);
                            // return event;
                        }
                    },
                    error: (error: HttpErrorResponse) => {
                        console.log(error);
                        return reject(error);
                    },
                });
        });
    }

    errorMgmt(error: HttpErrorResponse) {
        let errorMessage = '';
        if (error.error instanceof ErrorEvent) {
            // Get client-side error
            errorMessage = error.error.message;
        } else {
            // Get server-side error
            errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
        }
        return throwError(() => errorMessage);
    }
}
