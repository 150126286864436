import { MatSnackBar } from '@angular/material/snack-bar';
import { B360SnackbarComponent } from '../components/b360-snackbar/b360-snackbar.component';
import {
    DEFAULT_SNACKBAR_ERROR,
    DEFAULT_SNACKBAR_SUCCESS,
} from './shared.interfaces';

export abstract class B360SnackHelper {
    static showSnack(
        msg: string,
        type: 'SUCCESS' | 'ERROR' | 'CLEAN',
        snack: MatSnackBar,
        duration = 4500,
    ) {
        let msgData;

        switch (type) {
            case 'SUCCESS':
                msgData = {
                    ...DEFAULT_SNACKBAR_SUCCESS,
                    message: !msg.length ? 'snackbar.success' : msg,
                };
                break;
            case 'ERROR':
                msgData = {
                    ...DEFAULT_SNACKBAR_ERROR,
                    message: !msg.length ? 'snackbar.failed' : msg,
                };
                break;
            case 'CLEAN':
                msgData = {
                    message: msg,
                };
                break;
        }

        if (!msgData) {
            return;
        }

        return snack.openFromComponent(B360SnackbarComponent, {
            data: msgData,
            duration: duration,
            verticalPosition: 'top',
            horizontalPosition: 'center',
        });
    }
}
