import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';
import { TranslateModule } from '@ngx-translate/core';
import { IB360SnackbarData } from '@src/app/shared/helpers/shared.interfaces';

@Component({
    selector: 'b360-snackbar',
    imports: [MatIconModule, TranslateModule],
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: './b360-snackbar.component.html',
    styleUrls: ['./b360-snackbar.component.scss']
})
export class B360SnackbarComponent {
    public data: IB360SnackbarData = inject(MAT_SNACK_BAR_DATA);
}
