export const B360SnackMsg = {
    success: 'snackbar.success',
    error: 'אירעה שגיאה בביצוע הפעולה',
    formErrors: 'יש שגיאות בטופס (מסומנות באדום)',
    noSignees: 'לא נבחרו גורמי חתימה, יש לבחור לפחות אחד',
    processExistsForRecipients: 'קיים תהליך פעיל עבור הנמענים שנבחרו',
    nothingSelected: 'לא נבחר כלום',
    canDownloadOnlyApproved: 'ניתן להוריד רק כאלו שאושרו',
    noChangesMade: 'לא בוצעו שינויים',
    noProcessType: 'לא נבחר סוג תהליך',
    noRecipients: 'לא נבחרו נמענים',
    noProcessName: 'לא נבחר שם לתהליך',
    noDepartmentSelected: 'לא נבחר מחלקה',
    addressNotValid: 'כתובת לא תקינה',
    bankNotValid: 'פרטי חשבון לא תקינים',
    noProcessSelected: 'לא נבחר תהליך',
    dateError: 'תאריך שגוי',
    invalidValue: 'ערך לא תקין',
    noPlanSelected: 'לא נבחר תוכנית',
    mustAddCustomField: 'יש להוסיף שדה מותאם אישית',
    valueExists: 'הערך כבר קיים',
    noDepartmentNumber: 'לא נבחר מספר מחלקה',
    noDepartmentName: 'לא נבחר שם מחלקה',
    noFactoryFound: ' לא נמצא מפעל ',
    noFactoryNumber: 'לא נבחר מספר מפעל',
    noFactoryName: 'לא נבחר שם מפעל',
    fixForm: 'יש לתקן את הטופס',
    duplicatedIdNumberExists: 'קיימים כפילויות בתעודות זהות',
    cantSendFormThatIsEmployerPendingStatus:
        'לא ניתן לשלוח טופס כאשר קיים אחד הממתין לאישור מעסיק',
    changeRequiresNewIdPhotoUpload: 'שינוי זה דורש העלאת תמונת ת.ז חדשה',
    notAllAppendsUploaded: 'לא כל המסמכים צורפו',
    noSignature: 'חסרה חתימה',
    weWillReplayShourtly: 'פנייתך התקבלה נחזור אליך בהקדם',
    cantPublishPostWithNoText: 'לא ניתן לפרסם פוסט ללא תוכן',
    copiedToClipboard: 'words.copiedToClipboard',
};
