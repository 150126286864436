import {
    ChangeDetectionStrategy,
    Component,
    HostListener,
    inject,
} from '@angular/core';
import { Observable, map } from 'rxjs';
import { TPortal } from './shared/helpers/types';

import { PortalModule } from '@angular/cdk/portal';
import { AsyncPipe } from '@angular/common';
import { RouterOutlet } from '@angular/router';
import { PortalBridgeService } from './shared/services/portal-bridge.service';

@Component({
    selector: 'app-root',
    template: `
        @if(portal$ | async; as p){
        <ng-template [cdkPortalOutlet]="p"></ng-template>
        }

        <router-outlet></router-outlet>
    `,
    imports: [PortalModule, RouterOutlet, AsyncPipe],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent {
    private portalService = inject(PortalBridgeService);

    portal$: Observable<TPortal> = this.portalService.portal$.pipe(
        map((portal) => {
            if (portal?.portal && !portal.id) {
                return portal.portal;
            }
        }),
    );

    @HostListener('window:resize', ['$event'])
    onResize(event) {
        this.setViewportHeight();
    }

    ngOnInit() {
        console.log('here in start');
        this.setViewportHeight();

        //Add 'implements OnInit' to the class.
        if ('serviceWorker' in navigator) {
            navigator.serviceWorker.ready.then((registration) => {
                registration.unregister();
            });
        }
    }

    private setViewportHeight() {
        const vh = window.innerHeight * 0.01;
        document.documentElement.style.setProperty('--vh', `${vh}px`);
    }
}
