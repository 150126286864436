import { Injectable, inject } from '@angular/core';
import { Router } from '@angular/router';

import { BehaviorSubject, Observable, throwError } from 'rxjs';

import { Platform } from '@angular/cdk/platform';
import {
    HttpBackend,
    HttpClient,
    HttpErrorResponse,
    HttpEventType,
    HttpResponse,
} from '@angular/common/http';
import { FormControl } from '@angular/forms';
import { catchError, map, shareReplay } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

import { MatSnackBar } from '@angular/material/snack-bar';
import { B360SnackHelper } from '../helpers/B360SnackHelper';
import { EAppendTypes } from '../helpers/shared.enums';
import {
    ApiResponse,
    B360HttpOptions,
    IAwsSignedUrlFromApi,
    IB360PersignedUrlRequest,
    IErrorLogToApi,
    IModuleSchema,
} from '../helpers/shared.interfaces';
import { B360SnackMsg } from '../maps/b360-snack-messages';
import { HttpService } from './http.service';
declare let $: any;

interface APIResponse {
    ok: boolean;
    data?: any;
    error?: string;
    statusCode?: number;
}
@Injectable({
    providedIn: 'root',
})
export class BiskitsApi {
    private http = inject(HttpService);
    private httpBackEnd = inject(HttpBackend);
    private platform = inject(Platform);
    private router = inject(Router);
    private snack = inject(MatSnackBar);

    apiUrl = environment.api;

    uploadProgress = new BehaviorSubject<number>(0);

    private httpClientNoInterceptor: HttpClient;

    constructor() {
        this.httpClientNoInterceptor = new HttpClient(this.httpBackEnd);
    }

    async logError(errorLog: IErrorLogToApi) {
        return await this.post(this.apiUrl + '/api/errors/logError', errorLog);
    }

    confirmRegisteration(body) {
        return this.post(this.apiUrl + '/resource/confirm-register', body);
    }

    getModules() {
        return this.obsGet(this.apiUrl + `/api/business/modules`);
    }

    // async getRoutePermissions() {
    //     if (!this.routePermissions) {
    //         this.routePermissions = await this.get(
    //             this.apiUrl + `/api/permission/checkRoutePermission`
    //         );
    //     }
    //     return this.routePermissions;
    // }

    allowedNavList$: Observable<IModuleSchema>;
    getEmployeeAllowedNavList$() {
        if (this.allowedNavList$) return this.allowedNavList$;
        this.allowedNavList$ = this.oGet(
            this.apiUrl + `/api/permission/employeeAllowedNavList`,
        ).pipe(
            shareReplay(1),
            map((res) => {
                if (res.ok) return res.data;
                if (res.error) throw res.data;
            }),
        );

        return this.allowedNavList$;
    }

    registerForm(form) {
        return this.post(this.apiUrl + '/resource/register', form);
    }

    async hasActiveConcent() {
        return await this.get(this.apiUrl + '/api/payslips/concent');
    }

    async registerSub(sub) {
        return await this.post(
            this.apiUrl + '/api/notification/registerNotifSub',
            sub,
        );
    }

    async updateUserAppends(appends: any) {
        const res = await this.post(
            this.apiUrl + '/api/user/updateappends',
            appends,
        );
        return res;
    }

    async checkIfAppendExist(type: EAppendTypes | EAppendTypes[]) {
        const params = { type };
        const res = await this.get(this.apiUrl + '/api/user/checkappends', {
            params,
        });
        return res;
    }

    async getFiles(businessNumber: string): Promise<any> {
        const params = { businessNumber };
        const res = await this.get(this.apiUrl + '/business/files', { params });
        return res;
    }

    async getAllPendingFiles(): Promise<any> {
        const res = await this.get(this.apiUrl + '/api/business/bpcfile');
        return res;
    }

    async getRooms(): Promise<any> {
        const res = await this.get(this.apiUrl + '/business/rooms');
        return res;
    }

    async downloadFile(url: string): Promise<any> {
        const res = await this.get(url);
        return res;
    }

    downloadFile$(url: string): Observable<Blob> {
        return this.obsGet(url, { responseType: 'blob' });
    }

    async sendContactForm(form: FormControl): Promise<any> {
        return await this.post(this.apiUrl + '/api/business/contact', form);
    }

    confirm(data: { code: string; idNumber: string }): Observable<any> {
        const confirmData = {
            codeData: {
                code: data.code,
                idNumber: data.idNumber,
            },
            looger: {
                userData: {
                    userAgent: window.navigator.userAgent,
                    isMobileWrapper: window.matchMedia(
                        '(display-mode: standalone)',
                    ).matches,
                },
                ...this.platform,
            },
        };
        return this.obsPost(this.apiUrl + '/authAPI/confirm', confirmData);
    }

    getPreSignedUrl(
        obj: IB360PersignedUrlRequest,
    ): Observable<IAwsSignedUrlFromApi> {
        return this.oGet(this.apiUrl + '/api/presignedUrl', {
            params: obj,
        }).pipe(
            map((res) => {
                if (res.ok) {
                    return res.data;
                } else {
                    throw res.data;
                }
            }),
        );
    }

    downloadFileFromAws(obj: IB360PersignedUrlRequest) {
        return this.obsGet(this.apiUrl + '/api/downloadFileFromAws', {
            params: obj,
        });
    }

    // async getProfile(): Promise<any> {
    //     const profile = await this.get(this.apiUrl + '/authAPI/profile');
    //     // this.socket.emit('user', profile.employee._id);
    //     this.profile = profile;
    //     // this._registerUserOnChamiloin(profile);
    //     this._updateUserPrefOnLocalDb(profile);

    //     return profile;
    // }

    async updateUserImage(data: {
        profileImg: { type: string; value: string };
    }) {
        const result = await this.put(this.apiUrl + `/api/user`, data);
        return result;
    }

    async searchEmployee(param: string) {
        const res = await this.get(this.apiUrl + `/api/employee?key=${param}`);
        return res;
    }

    async signInPost(
        url: string,
        data: any = {},
        options: Partial<B360HttpOptions> = {},
    ) {
        options.withCredentials = true;
        const response = (await this.http.post(url, data, options)) as Response;
        if (response.ok) {
            return response;
        }
        throw new Error('Err');
    }

    async getFile(url: string, options: Partial<B360HttpOptions> = {}) {
        options.withCredentials = true;
        const response = (await this.http.get(url, options)) as Blob;
        return response;
    }

    // Channeling all API requests through these functions
    async get(url: string, options: Partial<B360HttpOptions> = {}) {
        options.withCredentials = true;
        const response = (await this.http.get(url, options)) as APIResponse;

        if (response.ok) {
            return response.data;
        }
        if (response.statusCode === 401) {
            this.router.navigate(['/business/401']);
            throw new Error('Not Authorized');
        }
        throw new Error(response.error);
    }

    obsGet(
        url: string,
        options: Partial<B360HttpOptions> = {},
    ): Observable<any> {
        options.withCredentials = true;
        return this.http.oGet(url, options).pipe(
            map((res) => {
                if (res.ok) {
                    return res.data;
                } else throw res.data;
            }),
        );
    }

    obsDelete(
        url: string,
        data: any = {},
        options: Partial<B360HttpOptions> = {},
    ): Observable<any> {
        options.withCredentials = true;
        return this.http.oDelete(url, data, options).pipe(
            map((res) => {
                if (res.ok) {
                    return res.data;
                } else {
                    throw res.data;
                }
            }),
        );
    }
    obsPut(
        url: string,
        data: any = {},
        options: Partial<B360HttpOptions> = {},
    ): Observable<any> {
        options.withCredentials = true;
        return this.http.oPut(url, data, options).pipe(
            map((res) => {
                if (res.ok) {
                    return res.data;
                } else {
                    throw res.data;
                }
            }),
        );
    }
    obsPatch(
        url: string,
        data: any = {},
        options: Partial<B360HttpOptions> = {},
    ): Observable<any> {
        options.withCredentials = true;
        return this.http.oPatch(url, data, options).pipe(
            map((res) => {
                if (res.ok) {
                    return res.data;
                } else {
                    throw res.data;
                }
            }),
        );
    }

    obsPost(
        url: string,
        data: any = {},
        options: Partial<B360HttpOptions> = {},
    ): Observable<any> {
        options.withCredentials = true;
        return this.http.oPost(url, data, options).pipe(
            map((res) => {
                if (res.ok) {
                    return res.data;
                } else {
                    throw res.data;
                }
            }),
        );
    }

    // need update
    obsPostWithFile(
        url: string,
        formData: FormData,
        options: Partial<B360HttpOptions> = {},
    ): Observable<any> {
        options.withCredentials = true;
        options.reportProgress = true;
        options.observe = 'events';

        return this.http.oPost(url, formData, options).pipe(
            map((event) => {
                if (event.type === HttpEventType.UploadProgress) {
                    const percentDone = Math.round(
                        (100 * event.loaded) / event.total,
                    );
                    this.uploadProgress.next(percentDone); // update progress
                } else if (event instanceof HttpResponse) {
                    if (event.body.ok) {
                        return event.body.data;
                    } else {
                        throw event.body.data;
                    }
                }
            }),
            catchError((error: HttpErrorResponse) => {
                console.error('File upload error:', error);
                return throwError(() => 'File upload error');
            }),
        );
    }

    oGet(
        url: string,
        options: Partial<B360HttpOptions> = {},
    ): Observable<APIResponse> {
        options.withCredentials = true;
        return this.http.oGet(url, options).pipe(
            catchError((err) => {
                return throwError(() => err);
            }),
        );
    }

    oPost(
        url: string,
        data: any = {},
        options: Partial<B360HttpOptions> = {},
    ): Observable<ApiResponse> {
        options.withCredentials = true;
        return this.http.oPost(url, data, options).pipe(
            catchError((err) => {
                B360SnackHelper.showSnack(
                    B360SnackMsg.error,
                    'ERROR',
                    this.snack,
                );
                return throwError(() => err);
            }),
        );
    }

    oPostFile(
        url: string,
        formData: FormData,
        options: Partial<B360HttpOptions> = {},
    ): Observable<ApiResponse> {
        options.withCredentials = true;
        options.reportProgress = true;
        options.observe = 'events';

        return this.http.oPost(url, formData, options).pipe(
            map((event) => {
                if (event.type === HttpEventType.UploadProgress) {
                    const percentDone = Math.round(
                        (100 * event.loaded) / event.total,
                    );
                    this.uploadProgress.next(percentDone); // update progress
                } else if (event instanceof HttpResponse) {
                    return event.body;
                }
            }),
            catchError((error: HttpErrorResponse) => {
                console.error('File upload error:', error);
                return throwError(() => 'File upload error');
            }),
        );
    }

    oPostNoError(
        url: string,
        data: any = {},
        options: Partial<B360HttpOptions> = {},
    ): Observable<ApiResponse> {
        options.withCredentials = true;
        return this.http.oPostFiles(url, data, options);
    }

    async delete(
        url: string,
        data: any = {},
        options: Partial<B360HttpOptions> = {},
    ) {
        options.withCredentials = true;
        // options.observe = 'response'
        const response = (await this.http.delete(
            url,
            data,
            options,
        )) as APIResponse;
        if (response.ok) {
            return response.data;
        }
        if (response.statusCode === 401) {
            this.router.navigate(['/business/401']);
            throw new Error('Not Authorized');
        }
        B360SnackHelper.showSnack(B360SnackMsg.error, 'ERROR', this.snack);
        throw new Error(response.error);
    }

    async post(
        url: string,
        data: any = {},
        options: Partial<B360HttpOptions> = {},
    ) {
        options.withCredentials = true;
        // options.observe = 'response'
        const response = (await this.http.post(
            url,
            data,
            options,
        )) as APIResponse;
        if (response.ok) {
            return response.data;
        }
        if (response.statusCode === 401) {
            this.router.navigate(['/business/401']);
            throw new Error('Not Authorized');
        }

        throw new Error(response.data);
    }

    async put(
        url: string,
        data: any = {},
        options: Partial<B360HttpOptions> = {},
    ) {
        options.withCredentials = true;
        const response = (await this.http.put(
            url,
            data,
            options,
        )) as APIResponse;
        if (response.ok) {
            return response.data;
        }
        if (response.statusCode === 401) {
            this.router.navigate(['/business/401']);
            throw new Error('Not Authorized');
        }
        B360SnackHelper.showSnack(B360SnackMsg.error, 'ERROR', this.snack);
        throw new Error(response.error);
    }
    async patch(
        url: string,
        data: any = {},
        options: Partial<B360HttpOptions> = {},
    ) {
        options.withCredentials = true;
        const response = (await this.http.patch(
            url,
            data,
            options,
        )) as APIResponse;
        if (response.ok) {
            return response.data;
        }
        if (response.statusCode === 401) {
            this.router.navigate(['/business/401']);
            throw new Error('Not Authorized');
        }
        throw new Error(response.error);
    }

    async postWithFile(
        url: string,
        data: any = {},
        options: Partial<B360HttpOptions> = {},
    ) {
        options.withCredentials = true;
        options.reportProgress = true;
        const response = (await this.http.post(
            url,
            data,
            options,
        )) as APIResponse;
        if (response.ok) {
            return response.data;
        }

        if (response.statusCode === 401) {
            this.router.navigate(['/business/401']);
            throw new Error('Not Authorized');
        }
        throw new Error(response.error);
    }
}
