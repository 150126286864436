// abort-controller.interceptor.ts
import {
    HttpEvent,
    HttpHandler,
    HttpInterceptor,
    HttpRequest,
} from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { HttpCancellationService } from '../services/http-cancellation.service';

@Injectable()
export class AbortControllerInterceptor implements HttpInterceptor {
    private cancellationService = inject(HttpCancellationService);

    intercept(
        req: HttpRequest<any>,
        next: HttpHandler,
    ): Observable<HttpEvent<any>> {
        // Create an AbortController
        const abortController = new AbortController();

        // Attach the signal (using 'as any' to bypass TS error)
        const reqWithSignal = req.clone({
            signal: abortController.signal as any,
        } as any);

        // Register the controller so we can abort it on navigation
        this.cancellationService.register(abortController);

        return next.handle(reqWithSignal).pipe(
            finalize(() => {
                // Clean up after request completes or errors
                this.cancellationService.unregister(abortController);
            }),
        );
    }
}
