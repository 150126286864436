export const AssetsMap = {
    icons: {
        social: './assets/icons/social.svg',
        attachments: './assets/b360Icons/attachedDocs.svg',
        groupMessage: './assets/icons/groupMessage.svg',
        blankAvatar: './assets/images/background/blankAvatar.png',
        addGroupImg: './assets/icons/addgroupimg.svg',
        home: './assets/icons/Home.svg',
        pension: './assets/icons/Pension.svg',
        employees: './assets/icons/pepole.svg',
        requests: './assets/icons/request.svg',
        tables: './assets/icons/tables.svg',
        docs: './assets/icons/files.svg',
        chat: './assets/icons/Chat.svg',
        chatOff: './assets/icons/chatOff.svg',
        settings: './assets/icons/Settings.svg',
        settingsProfile: './assets/icons/users.svg',
        support: './assets/icons/help.svg',
        addEmployee: './assets/icons/AddUser.svg',
        attendance: './assets/icons/calender.svg',
        payStubs: './assets/icons/salary.svg',
        tree: './assets/icons/orgTree.svg',
        f101: './assets/icons/101.svg',
        approveDocuments: './assets/icons/docs.svg',
        xml: './assets/icons/xml.svg',
        feedbacks: './assets/icons/savings.svg',
        f161: './assets/icons/161.svg',
        tiful: './assets/icons/Inquiries.svg',
        total161: './assets/icons/yitrot.svg',
        businesses: './assets/icons/business.svg',
        permissions: './assets/icons/users.svg',
        users: './assets/icons/usersNew.svg',
        missions: './assets/icons/missions.svg',
        generalSettings: './assets/icons/bag.svg',
        fndSettings: './assets/icons/factories.svg',
        kitsSettings: './assets/icons/kits.svg',
        contacts: './assets/icons/contactsOn.svg',
        addBizLogo: './assets/icons/addlogo.svg',
        addProfileImg: './assets/icons/profilePic.svg',
        employeePension: './assets/icons/Portfolio.svg',
        newPension: './assets/icons/seniority.svg',
        logOut: './assets/icons/logout.svg',
        chatContact: './assets/icons/chatContact.svg',
        userBlue: './assets/icons/newUser.svg',
        kitIcons: './assets/icons/kits.svg',
        kitsWhite: './assets/icons/KitWhite.svg',
        newDocument: './assets/icons/newTemp.svg',
        trash: './assets/icons/trash.png',
        businessPermission: './assets/icons/businessp.svg',
        factoryPermission: './assets/icons/factoryp.svg',
        departmentPermission: './assets/icons/departmentp.svg',
        benefits: './assets/icons/birthday.svg',
        userPopup: './assets/icons/userPopup.svg',
        templates: './assets/icons/templates.svg',
        activityLog: './assets/icons/sendLog.svg',
        people: './assets/icons/pepole.svg',
        notification: './assets/icons/notification.svg',
        news: './assets/icons/news.svg',
        rols: './assets/icons/role.svg',
        kit: './assets/icons/Kit.svg',
        buyMe: './assets/icons/BuyMe.svg',
        vaza: './assets/icons/vaza.png',
        timewatch: './assets/icons/timewatch.svg',
        roundLogo: './assets/icons/logoround.svg',
        clock: './assets/icons/clock.svg',
        pause: './assets/icons/pause.svg',
        FILL_FORM101: './assets/icons/101.svg',
        NEW_DOCUMENT: './assets/icons/docs.svg',
        NEW_PAYSLIP: './assets/icons/salary.svg',
        NEW_CHAT_MSG: './assets/icons/Chat.svg',
        phonebook: './assets/icons/contactsOn.svg',
        male: './assets/avatar/male.svg',
        female: './assets/avatar/female.svg',
        savings: './assets/icons/savings.svg',

        employeeStates: {
            pension: {
                c: './assets/icons/pensionC.svg',
                n: './assets/icons/pensionD.svg',
                s: './assets/icons/pensionP.svg',
            },
            kit: {
                c: './assets/icons/kitC.svg',
                n: './assets/icons/KitD.svg',
                s: './assets/icons/KitP.svg',
            },
            form101: {
                c: './assets/icons/101C.svg',
                n: './assets/icons/101D.svg',
                s: './assets/icons/101P.svg',
            },
            payslip: {
                c: './assets/icons/payslipC.svg',
                n: './assets/icons/payslipD.svg',
            },
        },
    },

    images: {
        chatBG: './assets/images/background/chatBG.png',
        man: './assets/images/man.svg',
        itemNotFound: './assets/images/itemNotFound.svg',
        greenEmpBG: './assets/images/GreenEmpBG.png',
        colorPreview: './assets/images/colorPreview.png',
        noData: './assets/images/nd.svg',
        location: './assets/images/location.svg',
    },

    backgrounds: {
        cellphoneMock: './assets/images/cellmock.png',
        iceCream: './assets/backgrounds/pat7.jpg',
        party: './assets/backgrounds/party_4.jpg',
        green: './assets/backgrounds/simplechic_hellomart-36.jpg',
    },

    emojis: {
        death: './assets/emojis/griff.svg',
        sick: './assets/emojis/sick.svg',
        holiday: './assets/emojis/hol.svg',
        military: './assets/emojis/mil.svg',
        regular: './assets/emojis/regular.svg',
        pregnancy: './assets/emojis/preg.svg',
        vacation: './assets/emojis/vac.svg',
    },
};

export const emojiToHebMap = {
    death: 'אבל',
    sick: 'מחלה',
    group: 'קבוצה',
    holiday: 'חג',
    military: 'מילואים',
    regular: 'רגיל',
    pregnancy: 'ח. לידה',
    vacation: 'חופשה',
    other: 'אחר',
    strike: 'שביתה',
};
