export const environment = {
    production: true,

    api: 'https://b360.hr',
    ws: 'wss://b360.hr',

    appVersion: require('../../package.json').version,
    recaptchaKey: '6LfAwXgeAAAAAC5Im_SDJichMYt8HnyyRaiYPA7K',
    googleMapKey: 'AIzaSyDjsO_SVs1DWVm78bxRVoBq-F2Y-995RHs',
    recaptchaV3: '6LekX7spAAAAADGgKvNhwNYKCUoS9nsN56mDaruD',
    kolZchutUrl:
        'https://www.kolzchut.org.il/he/%D7%A7%D7%A8%D7%A0%D7%95%D7%AA_%D7%91%D7%A8%D7%99%D7%A8%D7%AA_%D7%9E%D7%97%D7%93%D7%9C_%D7%91%D7%A4%D7%A0%D7%A1%D7%99%D7%94',

    VAPID: 'BK5CaDY7Bhw8gWCA36Y_bOeWkVKfr5SfYhaFYVkI7xtUaxCBEe6SqLDIHvE_RiK5UVnrKn02GlKEOSa5oZSdJf0',
    withRecaptcha: true,

    // Firebase
    firebaseConfig: {
        apiKey: 'AIzaSyAkoGRNoRPD__IJWhe33DmmA5YJJRuoiiI',
        authDomain: 'b360-5ba5a.firebaseapp.com',
        projectId: 'b360-5ba5a',
        storageBucket: 'b360-5ba5a.appspot.com',
        messagingSenderId: '478755478568',
        appId: '1:478755478568:web:dea00b1c09dafed4fca91d',
        measurementId: 'G-FRM3NLRT5T',
    },
};
