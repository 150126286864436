import { Platform } from '@angular/cdk/platform';
import { Injectable, inject } from '@angular/core';
import { Router } from '@angular/router';
import {
    IBusiness,
    ILoginCredsToApi,
} from '@src/app/shared/helpers/shared.interfaces';
import { BiskitsApi } from '@src/app/shared/services/biskits.api';
import { BehaviorSubject, Observable, tap } from 'rxjs';
import { withLoadingTracking } from '../shared/helpers/shared.const';

@Injectable({ providedIn: 'root' })
export class AuthService {
    private biskitsApi = inject(BiskitsApi);
    private platform = inject(Platform);
    private router = inject(Router);

    private _tempCreds$ = new BehaviorSubject<ILoginCredsToApi>(null);
    set creds(creds: ILoginCredsToApi) {
        this._tempCreds$.next(creds);
    }

    get creds(): ILoginCredsToApi {
        return this._tempCreds$.getValue();
    }

    set token(token: string) {
        localStorage.setItem('token', token);
    }

    get token(): string {
        return localStorage.getItem('token');
    }

    login(creds: ILoginCredsToApi) {
        return this.biskitsApi.obsPost(
            this.biskitsApi.apiUrl + '/authAPI/login',
            creds,
            withLoadingTracking({ fnName: 'login' }),
        );
    }

    logout(caller?: string) {
        console.log('logout', caller);
        localStorage.removeItem('token');

        return this.router.navigate(['./auth']);
        // (window.location.href = '/auth')
    }

    /**
     * @description change token to business token
     */
    changeToken(biz: IBusiness): Observable<string> {
        return this.biskitsApi
            .obsPost(
                `${this.biskitsApi.apiUrl}/authAPI/login/changeUserToken`,
                { biz },
            )
            .pipe(tap((t) => (this.token = t.token)));
    }

    confirmOTP(data: ILoginCredsToApi & { code: string }): Observable<any> {
        const confirmData = {
            codeData: {
                code: data.code,
                idNumber: data.idNumber,
            },
            looger: {
                userData: {
                    userAgent: window.navigator.userAgent,
                    isMobileWrapper: window.matchMedia(
                        '(display-mode: standalone)',
                    ).matches,
                },
                ...this.platform,
            },
        };

        return this.biskitsApi
            .obsPost(this.biskitsApi.apiUrl + '/authAPI/confirm', confirmData)
            .pipe(
                tap((t) => {
                    // create new session
                    sessionStorage.clear();

                    // set token
                    this.token = t.token;
                }),
            );
    }
}
