import { inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { AuthService } from '@src/app/Auth/auth.service';
import { EB360LocalDbs } from '@src/app/localDB';

import { datadogRum } from '@datadog/browser-rum';
import { environment } from '@src/environments/environment';
import { API, AUTH_API } from '@src/main';
import Gleap from 'gleap';
import { NgxIndexedDBService } from 'ngx-indexed-db';
import {
    BehaviorSubject,
    catchError,
    Observable,
    of,
    switchMap,
    take,
    tap,
} from 'rxjs';
import { B360Utils } from '../helpers/B360Utils';
import { withLoadingTracking } from '../helpers/shared.const';
import {
    IEmployee,
    IEmployeeBankDetailsToApi,
    UserProfile,
} from '../helpers/shared.interfaces';
import { BiskitsApi } from './biskits.api';
import { FireBaseService } from './firebase.service';

@Injectable({ providedIn: 'root' })
export class UserService {
    private biskitsApi = inject(BiskitsApi);
    private dbService = inject(NgxIndexedDBService);
    private router = inject(Router);
    private authService = inject(AuthService);
    private fb = inject(FireBaseService);

    /**
     * @description clear the user profile on logout
     */
    clearProfile() {
        this._profile$.next(null);
    }

    /**
     * @description
     * navigate to business side from employee or admin
     * changes the token back to business token
     * @returns void
     */
    navToBusiness() {
        return this.biskitsApi
            .obsPost(`${AUTH_API}/employeeToBiz`, {})
            .pipe(take(1))
            .subscribe({
                next: (res: { token: string; navTo: string }) => {
                    this.authService.token = res.token;
                    this._profile$.next(null);
                    this.router.navigate([res.navTo]);

                    // remove related data from local and session storage
                    this._removeEmployeeRelatedDataFromStorage();
                },
                error: (_) => {
                    // cancel navigation
                    return;
                },
            });
    }

    /**
     * @description when navigating to business side clear the employee related data
     */
    private _removeEmployeeRelatedDataFromStorage() {
        sessionStorage.clear();
        localStorage.removeItem('currentEmployee');
    }

    /**
     * @description
     * navigate to employee side from business
     * changes the token back to business token
     * @returns void
     */
    navToEmployee(id: string) {
        return this.biskitsApi
            .obsGet(`${AUTH_API}/bizToEmployee?id=${id}`)
            .pipe(take(1))
            .subscribe({
                next: (res: { token: string; navTo: string }) => {
                    this.authService.token = res.token;
                    this._profile$.next(null);
                    this.router.navigate([res.navTo]);
                },
                error: (_) => {
                    // cancel navigation
                    return;
                },
            });
    }

    get profile$(): Observable<UserProfile> {
        return this._profile$.asObservable();
    }

    get profileValue(): UserProfile {
        return this._profile$.getValue();
    }

    private _profile$ = new BehaviorSubject<UserProfile>(null);
    getProfile() {
        if (this._profile$.getValue()) return this._profile$.asObservable();
        return this.biskitsApi.obsGet(`${AUTH_API}/profile`).pipe(
            take(1),
            tap((p: UserProfile) => {
                if (typeof p === 'string' && p === 'agent') {
                    return p;
                }

                this._profile$.next(p);

                this._registerUserOnGleap(p);
                this._registerOnDatadog(p);
                this._updateUserPrefOnLocalDb(p);
            }),
            catchError((err) => {
                this.clearProfile();
                this.authService.logout('getProfile');
                return null;
            }),
        );
    }

    /**
     * @description will register the user on datadog for analytics
     */
    private async _registerOnDatadog(p: UserProfile) {
        datadogRum.setUser({
            id: p.employee._id,
            email: p.employee.email,
            name: `${p.employee.firstName} ${p.employee.lastName}`,
            companyName: p?.employee?.business?.companyName ?? '',
            employee: p.employee._id,
            b360Version: environment.appVersion,
        });
    }

    /**
     * @description partial update of employee profile
     */
    updateEmployeeProfileAndRefresh(data: Partial<IEmployee>) {
        return this.biskitsApi
            .obsPatch(
                `${API}/employee`,
                data,
                withLoadingTracking({
                    fnName: 'updateEmployeeProfileAndRefresh',
                }),
            )
            .pipe(
                take(1),
                tap(() => this.refreshUser()),
            );
    }

    /**
     * @description partial update of employee profile bankDetails
     * and refresh the user profile
     * @param data - the updated bankDetails fields
     * @param file - bankstatment to upload
     * @returns void
     */
    updateEmployeeBankDetailsAndRefreshProfile(
        data: Partial<IEmployeeBankDetailsToApi>,
    ) {
        const formData = new FormData();
        if (data?.bankStatement instanceof File) {
            formData.append('file', data?.bankStatement);
        }

        formData.append('data', JSON.stringify(data));

        return this.biskitsApi
            .obsPatch(`${API}/employee/updateEmpBankDetails`, formData)
            .pipe(
                take(1),
                tap(() => this.refreshUser()),
            );
    }

    updateProfile(data) {
        return this.biskitsApi.obsPut(`${API}/user`, data).pipe(
            take(1),
            tap(() => this.refreshUser()),
        );
    }

    refreshUser() {
        this._profile$.next(null);
        this.getProfile().pipe(take(1)).subscribe();
    }

    private _updateUserPrefOnLocalDb(profile: UserProfile) {
        this.dbService
            .getByKey<any>(EB360LocalDbs.USER_PREF, profile.employee._id)
            .pipe(
                take(1),
                switchMap((res) => {
                    if (!res) {
                        return this.dbService.add(EB360LocalDbs.USER_PREF, {
                            id: profile.employee._id,
                            data: {
                                theme: {
                                    mode: getUserPreferredMode(),
                                    primaryColor:
                                        profile.employee?.business?.color ??
                                        'default',
                                },
                                preferdLang: getUserLang().lang,
                                dir: getUserLang().dir,
                                locale: navigator.language,
                            },
                        });
                    }

                    return of(res);
                }),
            )
            .subscribe({
                next: (res) => {
                    this._updateThemeLangAndDir(res);
                },
            });
    }

    private _updateThemeLangAndDir(res: any) {
        // change body dir attribute
        const body = document.body;
        // change theme
        const theme = res.data.theme.mode;
        body.classList.remove('light_mode', 'dark_mode');
        body.classList.add(theme);
    }

    private _registerUserOnGleap(profile: UserProfile) {
        const name = `${profile?.employee?.firstName} ${profile?.employee?.lastName}`;
        /* GLEAP user identification and data */
        Gleap.identify(
            profile?.employee?._id,
            {
                name: name ?? 'לא ידוע',
                email: profile?.employee?.email ?? 'לא ידוע',
                phone: profile?.employee?.phoneNumber ?? 'לא ידוע',
                companyId: profile?.employee?.business?._id ?? 'לא ידוע',
                companyName:
                    profile?.employee?.business?.companyName ?? 'לא ידוע',
                customData: {
                    department: profile?.employee?.departmentText,
                    ...B360Utils.returnReadableBrowserData(),
                    ...B360Utils.returnReadableOSAndDeviceData(),
                    userSupportedLangs: navigator?.languages ?? 'לא ידוע',
                    selectedLang: getUserLang().lang,
                    b360Version: environment.appVersion,
                },
            },
            profile.glHash,
        );

        // // if eligible show survey
        // const isBusinessForSurvey =
        //     profile?.employee?.business?.companyNumber === '511357196';

        // const eligibleEmployeeNumbers = SURVAY_EMPLOYEES;
        // if (
        //     isBusinessForSurvey &&
        //     eligibleEmployeeNumbers.includes(profile?.employee?.employeeNumber)
        // ) {
        //     this.showNilitWomansDaySurvey();
        // }
        // const eligibleEmployeeNumbers = SURVAY_EMPLOYEES;
        // if (
        //     isBusinessForSurvey &&
        //     eligibleEmployeeNumbers.includes(profile?.employee?.employeeNumber)
        // ) {
        //     this.showNilitWomansDaySurvey();
        // }

        this.initPushNotifs();
    }

    initPushNotifs() {
        // this.fb.init();
    }

    showSurvay() {
        // Gleap.showSurvey('3g71ep', 'survey');
    }
}

function getUserLang() {
    // Get the complete language tag
    const completeLanguageTag = navigator.language;

    // Special case for 'en-IL'
    if (completeLanguageTag === 'en-IL') {
        return { lang: 'he', dir: 'rtl' };
    }

    // Extract the first part of the language tag for general cases
    const languageTag = completeLanguageTag.split('-')[0];

    // Create a mapping for the specific languages you're interested in
    const languageMapping = {
        en: { lang: 'en', dir: 'ltr' },
        he: { lang: 'he', dir: 'rtl' },
        ar: { lang: 'ar', dir: 'rtl' },
        am: { lang: 'am', dir: 'ltr' },
        ru: { lang: 'ru', dir: 'ltr' },
    };

    // Return the mapped language or default to 'he' (Hebrew)
    return languageMapping[languageTag] || { lang: 'he', dir: 'rtl' };
}

function getUserPreferredMode() {
    // Check if the user has a preference for dark mode
    if (
        window.matchMedia &&
        window.matchMedia('(prefers-color-scheme: dark)').matches
    ) {
        return 'dark_mode';
    }

    // Check if the user has a preference for light mode
    if (
        window.matchMedia &&
        window.matchMedia('(prefers-color-scheme: light)').matches
    ) {
        return 'light_mode';
    }

    // If neither, return a default (you can choose 'dark' or 'light' here based on your app's default setting)
    return 'light_mode';
}

export const SURVAY_EMPLOYEES = [
    '10852',
    '12258',
    '12449',
    '10016',
    '12281',
    '12477',
    '97042',
    '97541',
    '11336',
    '12411',
    '12492',
    '10013',
    '12090',
    '12115',
    '12309',
    '12321',
    '12438',
    '76280',
    '76309',
    '10761',
    '11182',
    '10121',
    '11971',
    '12140',
    '93807',
    '10023',
    '12265',
    '12314',
    '91444',
    '12450',
    '12460',
    '12462',
    '96162',
    '10988',
    '11383',
    '11415',
    '11940',
    '12068',
    '12151',
    '12290',
    '10117',
    '10611',
    '10696',
    '12106',
    '12498',
    '10733',
    '97036',
    '11459',
    '10830',
    '12163',
    '12311',
    '12428',
    '91115',
    '10555',
    '12439',
    '12155',
    '7163',
    '11999',
    '12275',
    '12370',
    '12353',
    '12121',
    '12132',
    '12270',
    '12271',
    '12444',
    '12494',
    '11863',
    '10014',
    '12374',
    '12487',
    '11889',
];
